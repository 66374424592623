import * as React from "react";
import {supabase} from "../../../config/supabaseClient.js";
import { useState, useEffect, stringify } from "react";
import dayjs from "dayjs";

import Button from "@mui/material/Button";
import { TextField, capitalize, Typography, Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export default function ParticipateInVarietySurveyDialogIndica(props) {
  const { UserData } = props;
  const oldSelection = UserData[0].LastVarietyIndicaVote;
  const [dialogtype, setDialogType] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [surveyInfo, setSurveyInfo] = useState(null);
  const [possiblePlants, setPossiblePlants] = useState([]);
  const [selectedPlant, setSelectedPlant] = useState("");
  const [plantData, setPlantData] = useState(null);
  const [allPlants, setAllPlants] = useState([]);
  const [participated, setParticipated] = useState(false);
  const [wrongRole, setWrongRole] = useState(false);

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handlePlantSelect = (plantname) => {
    setSelectedPlant(plantname);
  };

  useEffect(() => {
    if (UserData[0].role === "Registriert" || UserData[0].role === "Bann") {
      //console.log("1",UserData[0].role, "Falsche Rolle? ", wrongRole);
      setWrongRole(true);
    } else {
      setWrongRole(false);
      //console.log("2",UserData[0].role, "Falsche Rolle? ", wrongRole);
    }
  }, []);


  useEffect(() => {
    const MemberParticipated = async () => {
      try {
        const { data: latestSurvey, error: latestSurveyError } = await supabase
          .from("misc")
          .select("*")
          .eq("keyword", "Sortenumfrage")
          .order("created_at", { ascending: false })
          .limit(1);

        if (!latestSurvey || latestSurvey.length === 0) {
          return true; // Hier kannst du festlegen, wie du vorgehen möchtest, wenn keine Umfrage vorhanden ist
        }

        const ParticipantsIDIndica = latestSurvey[0].content?.ParticipantsIDIndica;

        if (ParticipantsIDIndica && ParticipantsIDIndica.includes(UserData[0].id)) {
          setParticipated(true);
          //console.log("is drin")
          return true;
        } else {
          setParticipated(false);
          //console.log("is nich drin")
          return false;
        }
      } catch (error) {
        console.error("Fehler beim Abrufen der Umfrage:", error);
        // Handle error appropriately
        return false;
      }
    };
    MemberParticipated();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getSurveyData();
      setSurveyInfo(result);
    };

    fetchData();
  }, []);

  const getSurveyData = async () => {
    try {
      // Finde die neueste Umfrage mit dem Keyword "Sortenumfrage"
      const { data: latestSurvey, error: latestSurveyError } = await supabase
        .from("misc")
        .select("id, keyword, content")
        .eq("keyword", "Sortenumfrage")
        .order("created_at", { ascending: false })
        .limit(1);

      if (latestSurveyError) {
        console.error(
          "Fehler beim Abrufen der neuesten Sortenumfrage:",
          latestSurveyError.message
        );
        return null;
      }

      if (!latestSurvey || latestSurvey.length === 0) {
        console.log("Keine Sortenumfrage gefunden.");
        return null;
      }

      const survey = latestSurvey[0];
      const surveyTitel = `${survey.keyword} ${survey.content.Umfragestart} - ${survey.content.Umfrageende}`;

      return {
        surveyTitel,
        surveyData: survey.content,
      };
    } catch (error) {
      console.error("Allgemeiner Fehler:", error.message);
      return null;
    }
  };

  const getRatedPlantnames = (latestRating) => {
    const hybrid = latestRating.content.Hybrid || "";
    const indica = latestRating.content.Indica || "";
    const sativa = latestRating.content.Sativa || "";

    // Filtere leere Zeichenketten und füge sie zu einem Array zusammen
    const ratedPlantnames = [hybrid, indica, sativa].filter(
      (plantname) => plantname !== ""
    );

    return ratedPlantnames;
  };

  useEffect(() => {
    const getPossiblePlants = async () => {
      try {
        // Finde alle Pflanzen mit dem neuen Keyword "Plants Data"
        const { data: allPlantsData, error: plantsDataError } = await supabase
          .from("misc")
          .select("content")
          .eq("keyword", "Plants Data");
  
        if (plantsDataError) {
          console.error("Fehler beim Abrufen der Pflanzendaten:", plantsDataError.message);
          return null;
        }
  
        // Wenn keine Pflanzendaten gefunden wurden, kehre frühzeitig zurück
        if (!allPlantsData || allPlantsData.length === 0) {
          console.log("Keine Pflanzendaten gefunden.");
          return null;
        }
  
        // Verarbeite die Daten aus dem ersten Eintrag (da wir erwarten, dass es nur einen gibt)
        const plants = allPlantsData[0].content;

              // Entferne Pflanzen mit Status "inaktive" und sortiere alphabetisch
      const activePlants = Object.keys(plants)
      .filter(plant => plants[plant].Status !== "inaktive")
      .sort((a, b) => a.localeCompare(b, "de", { sensitivity: "base" }));
  
            // Extrahiere Namen der Hybrid-Pflanzen unter den aktiven Pflanzen
            const IndicaPlants = activePlants.filter(
              plant => plants[plant].type === "Indica"
            );
  
        // Finde den neuesten Eintrag mit dem Keyword "Bewertungsumfrage"
        const { data: latestRating, error: ratingError } = await supabase
          .from("misc")
          .select("content")
          .eq("keyword", "Bewertungsumfrage")
          .order("created_at", { ascending: false })
          .limit(1);
  
        if (ratingError) {
          console.error("Fehler beim Abrufen der letzten Bewertungsumfrage:", ratingError.message);
          return null;
        }
  
        let availableIndicaPlants = IndicaPlants;
  
        // Wenn eine Bewertungsumfrage vorhanden ist, filtere die bereits gewählte Indica-Pflanze heraus
        if (latestRating && latestRating.length > 0 && latestRating[0].content.Indica) {
          availableIndicaPlants = IndicaPlants.filter(plant => plant !== latestRating[0].content.Indica);
        }
  
        setPossiblePlants(availableIndicaPlants);
        setAllPlants(Object.keys(plants).map(plantName => ({
          plantname: plantName,
          ...plants[plantName]
        })));

        //console.log(allPlants); // Überprüfe die Struktur und Inhalt von allPlants nach dem Laden

        
        return availableIndicaPlants;
      } catch (error) {
        console.error("Allgemeiner Fehler:", error.message);
        return null;
      }
    };
    getPossiblePlants();
  }, []);
  
  

  const handleParticipateInSurvey = async () => {
    try {
      // Überprüfe, ob eine Pflanze ausgewählt wurde
      if (!selectedPlant) {
        console.error("Es wurde keine Pflanze ausgewählt.");
        return;
      }

      // Finde den neuesten Eintrag mit dem Keyword "Sortenumfrage"
      const { data: latestSurvey, error: latestSurveyError } = await supabase
        .from("misc")
        .select("*")
        .eq("keyword", "Sortenumfrage")
        .order("created_at", { ascending: false })
        .limit(1);
      //console.log("Latest Survey:", latestSurvey);
      if (latestSurveyError) {
        console.error(
          "Fehler beim Abrufen der neuesten Sortenumfrage:",
          latestSurveyError.message
        );
        return;
      }

      if (!latestSurvey || latestSurvey.length === 0) {
        console.error("Keine Sortenumfrage gefunden.");
        return;
      }

      const latestSurveyContent = latestSurvey[0]?.content;
      //console.log("Latest Survey Content:", latestSurveyContent);

      // Erhöhe den Wert in der Spalte content mit dem letzten Eintrag Sortenumfrage
      // um 1 für die ausgewählte Pflanze
      const currentCount = latestSurveyContent[selectedPlant] || 0;
      const newCount = currentCount + 1;
      //console.log(currentCount)
      //console.log(newCount)
      // Aktualisiere die Sortenumfrage-Daten in der Datenbank
      

      // Füge die UserID zum Participants-Array hinzu
      const { error: updateParticipantsError } = await supabase
        .from("misc")
        .update([
          {
            content: {
              ...latestSurveyContent,
              [selectedPlant]: newCount,
              ParticipantsIDIndica: [
                ...latestSurveyContent.ParticipantsIDIndica,
                UserData[0].id,
              ],
            },
          },
        ])
        .eq("id", latestSurvey[0].id);

      if (updateParticipantsError) {
        console.error(
          "Fehler beim Hinzufügen des Benutzers zur Sortenumfrage:",
          updateParticipantsError.message
        );
        return;
      }

      // Trage den Wert von selectedPlant in die Tabelle User in die Spalte LastVarietyVote der jeweiligen UserID ein
      const { error: updateUserError } = await supabase
        .from("User")
        .update({ LastVarietyIndicaVote: selectedPlant })
        .eq("id", UserData[0].id);

      if (updateUserError) {
        console.error(
          "Fehler beim Aktualisieren des Benutzers:",
          updateUserError.message
        );
        return;
      }

      //console.log("Teilnahme erfolgreich abgeschlossen!");
    } catch (error) {
      console.error("Allgemeiner Fehler:", error.message);
    }
    handleCloseDialog();
    window.location.reload(false);
  };

  const editAnswer = async (oldSelection, newSelection) => {
    try {
      // Überprüfe, ob eine Pflanze ausgewählt wurde
      if (!oldSelection || !newSelection) {
        console.error("Es wurde keine Pflanze ausgewählt.");
        return;
      }

      // Finde den neuesten Eintrag mit dem Keyword "Sortenumfrage"
      const { data: latestSurvey, error: latestSurveyError } = await supabase
        .from("misc")
        .select("*")
        .eq("keyword", "Sortenumfrage")
        .order("created_at", { ascending: false })
        .limit(1);

      if (latestSurveyError) {
        console.error(
          "Fehler beim Abrufen der neuesten Sortenumfrage:",
          latestSurveyError.message
        );
        return;
      }

      if (!latestSurvey || latestSurvey.length === 0) {
        console.error("Keine Sortenumfrage gefunden.");
        return;
      }

      const latestSurveyContent = latestSurvey[0]?.content;

      // Vorbereitung für alte Auswahl
      const currentCountOld = latestSurveyContent[oldSelection] || 0;
      
      // Vorbereitung für neue Auswahl
      const currentCountNew = latestSurveyContent[newSelection] || 0;

      // Aktualisiere die Sortenumfrage-Daten in der Datenbank für die neue Auswahl
      const { error: updateNewSelectionError } = await supabase
        .from("misc")
        .update([
          {
            content: {
              ...latestSurveyContent,
              [oldSelection]: currentCountOld - 1,
              [newSelection]: currentCountNew + 1,
            },
          },
        ])
        .eq("id", parseInt(latestSurvey[0].id));

      if (updateNewSelectionError) {
        console.error(
          "Fehler beim Aktualisieren der Sortenumfrage für die neue Auswahl:",
          updateNewSelectionError.message
        );
        return;
      }

      // Trage den Wert von selectedPlant in die Tabelle User in die Spalte LastVarietyVote der jeweiligen UserID ein
      const { error: updateUserError } = await supabase
        .from("User")
        .update({ LastVarietyIndicaVote: newSelection })
        .eq("id", UserData[0].id);

      if (updateUserError) {
        console.error(
          "Fehler beim Aktualisieren des Benutzers:",
          updateUserError.message
        );
        return;
      }
      // Weitere Aktionen für die Teilnahme an der Umfrage (wie das Hinzufügen zur ParticipantsID usw.)
      // ...

      //console.log("Bearbeitung erfolgreich abgeschlossen!");
    } catch (error) {
      console.error("Allgemeiner Fehler:", error.message);
    }
    handleCloseDialog();
    window.location.reload(false);
  };

  return (
    <React.Fragment>
      <Button
        color="success"
        className="button"
        variant="contained"
        onClick={() => {
          handleClickOpen();
          setDialogType("Teilnehmen");
        }}
        disabled={participated || wrongRole}
        sx={{ marginTop: '25px !important' }}
      >
        Teilnehmen Indica
      </Button>
      <Button
        color="success"
        className="button"
        variant="contained"
        onClick={() => {
          handleClickOpen();
          setDialogType("Antwort bearbeiten");
        }}
        disabled={!participated || wrongRole}
        sx={{ marginTop: '0px !important' }}
      >
        Antwort bearbeiten
      </Button>
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle> {surveyInfo?.surveyTitel}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ marginTop: 2 }}>
            {dialogtype === "Antwort bearbeiten" && (
              <Typography variant="body1" marginBottom={2}>
                Deine alte Auswahl: {UserData[0].LastVarietyIndicaVote}
              </Typography>
            )}
            <Select
              value={selectedPlant}
              onChange={(e) => handlePlantSelect(e.target.value)}
            >
              {possiblePlants.map((plantname) => (
                <MenuItem key={plantname} value={plantname}>
                  {plantname}
                </MenuItem>
              ))}
            </Select>
            {selectedPlant && (
              <Box padding={2}>
                <Typography variant="h6">Weitere Daten:</Typography>
                {allPlants.map((plant) => {
  if (plant.plantname === selectedPlant) {
    const { CBD, THC, type, Taste, Effect, extras, BloomDuration } = plant;
    return (
      <div key={selectedPlant}>
        <Typography variant="body1">Pflanzentyp: {type}</Typography>
        <Typography variant="body1">THC: {THC} %</Typography>
        <Typography variant="body1">CBD: {CBD}</Typography>
        <Typography variant="body1">Geschmack: {Taste.join(", ")}</Typography>
        <Typography variant="body1">Effekt: {Effect.join(", ")}</Typography>
        <Typography variant="body1">Zusätzliche Informationen: {extras}</Typography>
      </div>
    );
  }
  return null;
})}
              </Box>
            )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Schließen</Button>
          <Button
            onClick={() => {
              if (dialogtype === "Antwort bearbeiten") {
                editAnswer(oldSelection, selectedPlant); // Funktion aufrufen, wenn dialogtype "Antwort bearbeiten" ist
              } else {
                handleParticipateInSurvey(); // Andernfalls handleParticipateInSurvey aufrufen
              }
            }}
          >
            Absenden
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
