import React from "react";
import { Link } from "react-router-dom";
import { styles } from "../styles";
import "../index.css";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Linkleiste from "../components/LinkLeiste.js";
import Logo from "../components/Logo.js";

const Beitragsordnung = () => {
  const isScreenSmall = useMediaQuery("(max-width:750px) and (min-width: 0px)");

  const [value, setValue] = React.useState("1");
  const minWindoSize = 500;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  return (
    <HelmetProvider>
      <div className="page-beitraege">
        <Helmet>
          <title>Beiträge der Anbauvereinigung Hemp Heaven Hannover</title>
          <meta
            name="description"
            content="Informiere dich über die Beiträge die in der Anbauvereinigung Hemp Heaven Hannover anfallen können und mache dich vertrauter damit wie der Verein Funktioniert"
          />
          <meta
            name="keywords"
            content="Anbauvereinigung, Hannover, Cannabis, CSC, Cannabis Social Club, Marihuana, Hash, Haschisch, Dope, Weed"
          />
        </Helmet>
        <Box
          component={Paper}
          sx={{
            maxWidth: 800,
            minWidth: 300,
            marginTop: 5,
            marginLeft: "auto",
            marginRight: "auto",
            alignItems: "center",
            padding: "2%",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <Logo />
          <h1
            style={{
              whiteSpace: "pre-line",
              fontSize: window.innerWidth < minWindoSize ? "30px" : "40px",
            }}
          >
            Beitragsordnung Hemp Heaven Hannover
          </h1>

          <h2 style={{ ...styles.subTitelText }}>§ 1 Basis-Mitgliedsbeitrag</h2>
          <p style={{ ...styles.normalText, margin: 5, padding: 0 }}>
            (1) Der Basis-Mitgliedsbeitrag beträgt 25 € pro Monat. Von diesem
            Beitrag finanziert der Verein die laufenden Kosten der Produktion
            exklusive des Stromverbrauchs, die laufenden Kosten des Vereinsheims
            und die allgemeinen Kosten (Kontoführungsgebühren, Domainkosten,
            Internet- und Mobilfunk-Verträge weitere, allgemeine laufende
            kosten). Der Basis-Mitgliedsbeitrag ist für alle Mitglieder
            verpflichtend.
          </p>
          <p style={{ ...styles.normalText, margin: 5, padding: 0 }}>
            (2) Ehrenmitglieder sind von diesem Beitrag befreit.
          </p>

          <h2 style={{ ...styles.subTitelText }}>
            § 2 Bedarfs-Mitgliedsbeitrag
          </h2>
          <p style={{ ...styles.normalText, margin: 5, padding: 0 }}>
            Der Bedarfs-Mitgliedsbeitrag beträgt 7 € pro Gramm Cannabis. Jedes
            Mitglied, das sich für die Pflanzenpflege innerhalb des befriedeten
            Besitztums des Vereins entschieden hat, kann seinen monatlichen
            Bedarf in Gramm innerhalb des gesetzlichen Rahmens selbst bestimmen.
            Der Beitrag berechnet sich aus den Verbrauchsgütern für die
            Pflanzenzucht (Pflanzenmedien, Düngemittel, Samen, Hygieneartikel,
            Aktivkohlefilter, Schädlingsschutz), dem Stromverbrauchskosten für
            die Pflanzenzucht, Personalkosten, sowie einem Betrag zum Ausgleich
            von Preisschwankungen und unerwartet auftretenden Kosten.
          </p>

          <h2 style={{ ...styles.subTitelText }}>§ 3 Erstausstattungsgebühr</h2>
          <p style={{ ...styles.normalText, margin: 5, padding: 0 }}>
            Die Erstausstattungsgebühr ist für die Beschaffung der Ausrüstung,
            die für die Pflanzenzucht und die Abgabe im Vereinsheim notwendig
            ist, und wird ausschließlich für die Erreichung der in der
            Vereinssatzung unter § 2 angegebenen Zwecke verwendet. Die Höhe der
            Erstausstattungsgebühr beläuft sich auf 500 €. Die
            Erstausstattungsgebühr ist nur dann zu entrichten, wenn sich das
            Mitglied für eine Pflanzenpflege innerhalb des befriedeten
            Besitztums des Vereines entscheidet.
          </p>

          <h2 style={{ ...styles.subTitelText }}>§ 4 Sonderbeiträge</h2>
          <p style={{ ...styles.normalText, margin: 5, padding: 0 }}>
            Privatpflege-Mitglieder oder Vereinspflege-Mitglieder, die von ihrem
            angegebenen Monatsbedarf abweichen, können im Vereinsheim zu einem
            Sonderbeitrag von 9 € pro Gramm zusätzliches Cannabis erhalten. Die
            Abgaben können nur dann geleistet werden, wenn aus dem Lagerbestand
            ersichtlich ist, dass bis zur nächsten Ernte kein Leerstand
            entsteht. Die Sicherstellung der Abgabe an die
            Vereinspflege-Mitglieder ist zu priorisieren.
          </p>

          <h2 style={{ ...styles.subTitelText }}>
            § 5 Unwirksamkeit von Teilen der Beitragsordnung
          </h2>
          <p style={{ ...styles.normalText, margin: 5, padding: 0 }}>
            Bei Unwirksamkeit von Teilen der in der Beitragsordnung enthaltenen
            Bestimmungen bleibt der übrige Teil der Beitragsordnung voll
            wirksam.
          </p>
          {/* <Text style={styles.titleText}>Hanffarm AV Hannover {"\n"}</Text> */}
          {/*                 <div style={{ textAlign: "center" }}>
                    <span style={{ color: "red", fontWeight: "bold" }}>
                      Momentan nehmen wir keine neuen Mitglieder auf. Ihr könnt
                      euch dennoch auf dieser Website registrieren und werdet
                      dadurch auf eine Warteliste aufgenommen.
                    </span>
                  </div>
                  {"\n"}
                  {"\n"} */}

          {/* <h1
          style={{
            whiteSpace: "pre-line",
            fontSize: window.innerWidth < minWindoSize ? "30px" : "40px",
          }}
        >
          Basis-Mitgliedsbeitrag
        </h1>
        <p style={styles.normalText}>
          Modell Vereinspflege und Privatpflege: Der Basis-Mitgliedsbeitrag
          beträgt 25 € im Monat. Mit dem Basis-Mitgliedsbeitrag werden die
          Fixkosten der Anbauvereinigung bezahlt (Miete, Strom des Vereinsheims,
          Versicherungen, etc.). Dieser Beitrag wird in jährlichen
          Vereinsversammlungen an die Bedürfnisse der Anbauvereinigung und seiner
          Mitglieder angepasst. Der Basis-Mitgliedsbeitrag wird von allen
          Mitgliedern, außer Ehrenmitgliedern, gezahlt.
        </p>
        {"\n"}
        {"\n"}

        <h1
          style={{
            whiteSpace: "pre-line",
            fontSize: window.innerWidth < minWindoSize ? "30px" : "40px",
          }}
        >
          Bedarfs-Mitgliedsbeitrag
        </h1>
        <p style={styles.normalText}>
          Modell Vereinspflege: Entscheidest du dich für die Vereinspflege,
          berechnet sich der monatliche Mitgliedsbeitrag nach deiner angegebenen
          Konsummenge. Dafür wird für jedes Gramm Cannabis 7 € auf den
          Basis-Mitgliedsbeitrag addiert. Der Cannabis-Preis berechnet sich aus
          Parametern wie Stromverbrauch der Produktionshalle, Düngemittel-,
          Wachstumsmediumspreisen, Verbrauchs- und Pflegematerialkosten sowie
          Personalkosten und weiteren auftretenden Kosten. Der
          Gesamtmitgliedsbeitrag wird per SEPA-Lastschriftmandat Mitte des
          Vormonats vom angegebenen Konto abgebucht. Eine Rückerstattung bei
          einem geringeren Konsum als der angegebenen Konsummenge ist
          ausgeschlossen. Wird die Konsummenge angepasst, gilt die neue
          Konsummenge erst für den Folgemonat ab dem 1. eines jeden Monats. Du
          kannst deine Konsummenge zu jeder Zeit für den Folgemonat anpassen.
          Personen, die sich für die Vereinspflege entscheiden werden bei der
          Mitgliederaufnahme bevorzugt. Mitglieder mit dem Modell der
          Privatpflege können keinen Bedarfs-Mitgliedsbeitrag zahlen. Um
          Cannabis zu erhalten, müssen Privatpflegemitglieder Sonderbeiträge
          zahlen, siehe dafür weiter unten.
        </p>
        <h1
          style={{
            whiteSpace: "pre-line",
            fontSize: window.innerWidth < minWindoSize ? "30px" : "40px",
          }}
        >
          Erstausstattungsgebühr
        </h1>
      <p style={styles.normalText}>
        Der Verein ist selbstfinanziert. Das bedeutet, dass derzeit keine
        Sponsoren vorhanden sind. Falls jemand interessiert ist, den Verein zu
        sponsorn, zu spenden oder ein Privatdarlehen zu entrichten, meldet
        euch gerne bei uns (vorstand@hemp-heaven-hannover.de). Daher ist es
        erforderlich, von jedem Mitglied, das sich für die Vereinspflege
        entscheidet, bei der Anmeldung eine Erstausstattungsgebühr zu
        verlangen. Mit dieser Gebühr wird die notwendige Ausrüstung zum Anbau
        der Pflanzen erworben. Bei der Auswahl der Ausrüstung wurde darauf
        Wert gelegt, das beste Preis-Leistungsverhältnis zu erhalten.
      </p>
      <p style={styles.boldText}>
        Die Erstausstattungsgebühr beläuft sich auf etwa 500 €, von denen ca.
        300 € durch das Abkaufen der Ausstattung zurückgegeben werden können.
        Mit einem Klick auf das Bild wirst du zu einer genaueren Auflistung
        der Erstausstattung weitergeleitet. Der Vorstand arbeitet gerade daran
        Privatdarlehen zu organisieren um damit die Kosten der
        Erstausstattung zu senken.
      </p>
      <p style={styles.normalText}>
        Modell Privatpflege: Wenn du dich für die Privatpflege entscheidest,
        dann bist du für die Beschaffung des Equipments, Sicherheitsmaßnahmen,
        Unterbringung und Pflanzenpflege selbst verantwortlich. Außerdem sind
        die Plätze für Mitglieder der Privatpflege stark begrenzt.
      </p>
    
    <Link title= "Zur Erstausstattungsübersicht" to="/equipment">
        <img title="Ausrüstung" src={require(`../images/Equipment.webp`)} alt="Bild der voraussichtlichen Ausrüstung"/>
    </Link>

        <p style={styles.normalText}>
          Ein weiteres Ziel des Vereins ist es, im Laufe der Zeit den
          Mitgliedern den großteil der Erstausstattungsgebühr zurückzuerstatten
          in dem wir euch die Ausstattung abkaufen. Dies wird jedoch nicht garantiert,
          ist aber in der Finanzplanung fest vorgesehen. Um dieses Ziel
          zuerreichen Arbeitet der Vorstand vorerst Ehrenamtlich.
        </p>
        <p style={styles.normalText}>
          Zu einem späteren Zeitpunkt, wenn alle Prozesse sicher, standardisiert
          und Schulungsmaterial angefertigt ist, kann sich jedes Mitglied als
          ehrenamtliches Pflegemitglied melden und sich an der Aufzucht der
          Pflanzen beteiligen. Bei einer bestimmten Menge an geleisteten
          ehrenamtlichen Stunden wird eine Ehrenamtspauschale von maximal 840 €
          an das jeweilige Mitglied im Jahr ausgezahlt.
        </p>
        <h1
          style={{
            whiteSpace: "pre-line",
            fontSize: window.innerWidth < minWindoSize ? "30px" : "40px",
          }}
        >
          Sonderbeiträge
        </h1>
        <p style={styles.normalText}>
          Wird in einem Monat mehr Cannabis als angegeben benötigt, kann dieses
          durch Sonderbeiträge für 9 € im Vereinsheim abgeholt werden (Solange
          dadurch nicht die gesetzlichen Vorgaben zur monatlichen Maximalmenge
          überschritten werden). Die gilt sowohl für das Vereinspflege-Modell,
          als auch für das Privatpflege-Modell. Ein Mitglied, das sich für die
          Privatpflege entscheidet, zahlt monatlich nur den
          Basis-Mitgliedsbeitrag. Durch die Privatpflege erhältst du die
          Möglichkeit, Vermehrungsmaterial für Cannabispflanzen im Vereinsheim
          zu erwerben. Falls der Lagerbestand des Vereins es hergibt, kannst du
          als Privatpflegemitglied Cannabis im Vereinsheim für 9 € pro Gramm
          erhalten.
        </p> */}
        </Box>
      </div>
    </HelmetProvider>
  );
};

export default Beitragsordnung;
