import React from "react";
import Button from "@mui/material/Button";
import {supabase} from "../../../config/supabaseClient";
import * as XLSX from "xlsx";

const GenerateMemberFeeList = () => {
  const generateMemberFeeList = async () => {
    try {
      // Daten aus Supabase abrufen
      const { data, error } = await supabase
        .from("User")
        .select(
          "lastname, firstname, mandat_reference, demand_next_month, special_contribution, model, role"
        );

      if (error) {
        console.error(
          "Fehler beim Abrufen der Daten aus Supabase:",
          error.message
        );
        return;
      }

      // Filtern der Daten, um nur registrierte Benutzer auszuschließen
      const filteredData = data.filter((row) => row.role !== "Registriert");

      // Excel-Daten vorbereiten
      const rows = [
        [
          "Nachname",
          "Vorname",
          "Mitgliedsmodel",
          "Vereinsrolle",
          "Mandatsreferenz",
          "neuer Mitgliedsbeitrag (€)",
        ],
        ...filteredData.map((row) => {
          let newFee;
          if (row.model === "Ehrenmitglied-Vereinspflege") {
            newFee = (
              parseFloat(row.demand_next_month) * 6 +
              parseFloat(row.special_contribution) * 8
            ).toFixed(0);
          } else if (row.model === "Ehrenmitglied-Privatpflege") {
            newFee = (parseFloat(row.special_contribution) * 8).toFixed(0);
          } else {
            newFee =
              row.role === "Prime-Mitglied"
                ? (
                    parseFloat(row.demand_next_month) * 6 +
                    parseFloat(row.special_contribution) * 8 +
                    25
                  ).toFixed(0)
                : (
                    parseFloat(row.demand_next_month) * 7 +
                    parseFloat(row.special_contribution) * 9 +
                    25
                  ).toFixed(0);
          }
          return [
            row.lastname,
            row.firstname,
            row.model,
            row.role,
            row.mandat_reference,
            parseFloat(newFee), // Als Zahl übergeben
          ];
        }),
      ];

      // Arbeitsblatt erstellen
      const worksheet = XLSX.utils.aoa_to_sheet(rows);

      // Formatierung der Spalte "neuer Mitgliedsbeitrag (€)"
      const range = XLSX.utils.decode_range(worksheet["!ref"]);
      for (let row = 1; row <= range.e.r; row++) {
        const cellAddress = XLSX.utils.encode_cell({ r: row, c: 5 }); // Spalte 6 (Index 5)
        if (worksheet[cellAddress]) {
          worksheet[cellAddress].z = "#.##0,0 €"; // Währungsformat
        }
      }

      // Arbeitsmappe erstellen und herunterladen
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Mitgliedsbeiträge");

      const currentDate = new Date()
        .toISOString()
        .slice(0, 10)
        .replace(/-/g, "_");
      const fileName = `${currentDate}_Mitgliedsbeiträge.xlsx`;
      XLSX.writeFile(workbook, fileName);
    } catch (error) {
      console.error("Ein Fehler ist aufgetreten:", error.message);
    }
  };

  return (
    <Button
      className="button"
      color="success"
      variant="contained"
      onClick={generateMemberFeeList}
      sx={{
        "@media screen and (max-width: 600px)": {
          fontSize: 10, // Stil für kleinere Bildschirme
        },
      }}
    >
      Beitragsliste erstellen
    </Button>
  );
};

export default GenerateMemberFeeList;
