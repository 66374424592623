import React, { useState } from "react";
import { styles } from "../styles";
import Box from "@mui/material/Box";
import "./LinkLeiste.css";

import { ReactComponent as Groups } from "../images/Icons/groups.svg";
import { ReactComponent as Home } from "../images/Icons/home.svg";
import { ReactComponent as Monitoring } from "../images/Icons/monitoring.svg";
import { ReactComponent as Person } from "../images/Icons/person.svg";
import { ReactComponent as Yard } from "../images/Icons/yard.svg";
import { ReactComponent as Instagram } from "../images/Icons/icons8-instagram.svg";
import { ReactComponent as Euro } from "../images/Icons/euro.svg";
import { ReactComponent as Health } from "../images/Icons/health.svg";
import { ReactComponent as Kodex } from "../images/Icons/kodex.svg";
import { ReactComponent as Person_add } from "../images/Icons/person_add.svg";
import { ReactComponent as Plant } from "../images/Icons/plant.svg";
import { ReactComponent as Assignment } from "../images/Icons/assignment.svg";
import { ReactComponent as Equipment } from "../images/Icons/equipment.svg";
import { ReactComponent as PottedPlant } from "../images/Icons/potted_plant.svg";

const Linkleiste = () => {
  // to change burger classes
  const [burger_class, setBurgerClass] = useState("burger-bar unclicked");
  const [menu_class, setMenuClass] = useState("menu hidden");
  const [isMenuClicked, setIsMenuClicked] = useState(false);

  // toggle burger menu change
  const updateMenu = () => {
    if (!isMenuClicked) {
      setBurgerClass("burger-bar clicked");
      setMenuClass("menu visible");
    } else {
      setBurgerClass("burger-bar unclicked");
      setMenuClass("menu hidden");
    }
    setIsMenuClicked(!isMenuClicked);
  };

  const menuItems = [
    { label: "Startseite", href: "/", icon: <Home />, alt: "Startseite" },
    {
      label: "Vereinsprinzip",
      href: "/Vereinsprinzip",
      icon: <Groups />,
      alt: "Vereinsprinzip",
    },
    {
      label: "Beitragsordnung",
      href: "/Beitragsordnung",
      icon: <Euro />,
      alt: "Beitragsordnung",
    },
    {
      label: "Vereinskodex",
      href: "/Vereinskodex",
      icon: <Kodex />,
      alt: "Vereinskodex",
    },
    {
      label: "Vereinssatzung",
      href: "/Vereinssatzung",
      icon: <Assignment />,
      alt: "Vereinssatzung",
    },
    {
      label: "Gesundheit & Prävention",
      href: "/Gesundheit",
      icon: <Health />,
      alt: "Gesundheit & Prävention",
    },
    {
      label: "Mitglied werden",
      href: "/Mitgliedwerden",
      icon: <Person_add />,
      alt: "Mitglied werden",
    },
    {
      label: "Sorten-Tabelle",
      href: "/Sorten-Tabelle",
      icon: <Plant />,
      alt: "Sorten-Tabelle",
    },
    {
      label: "Stecklinge",
      href: "/Stecklinge",
      icon: <PottedPlant />,
      alt: "Stecklinge",
    },
    {
      label: "Ausrüstung",
      href: "/Ausruestung",
      icon: <Equipment />,
      alt: "Ausrüstung",
    },
    {
      label: "Galerie",
      href: "/Galerie",
      icon: <Yard />,
      alt: "Galerie",
    },
    {
      label: "Instagram",
      href: "https://www.instagram.com/hempheavenhannover?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==",
      target: "_blank",
      rel: "noopener noreferrer",
      icon: <Instagram />,
      alt: "Instagram",
    },
  ];

  return (
    <div>
      <Box
        display="flex"
        alignItems="flex-end"
        justifyContent="center"
        flexDirection="column" // Änderung der Flex-Richtung
        flexWrap="wrap"
      >
        <div className="burger-menu" onClick={updateMenu}>
          <div className={burger_class}></div>
          <div className={burger_class}></div>
          <div className={burger_class}></div>
        </div>
        <div className={menu_class}>
          {menuItems.map((item, index) => (
            <div key={index} className="link-item">
              <a
                href={item.href}
                title={item.label}
                className="link-item-label" // Füge die Klasse für den Text hinzu
                style={{ 
                  fontSize: "18px", // Schriftgröße
                  fontWeight: "bold", // Schriftgewicht
                  color: "#333", // Schriftfarbe
                  width: "100%", // Breite
                }}
                target={item.label === "Instagram" ? "_blank" : null}
                rel={item.label === "Instagram" ? "noopener noreferrer" : null}
              >
                {item.label}
              </a>
              {item.icon && (
                <div className="link-item-icon">
                  {React.cloneElement(item.icon, {
                    style: { width: "25px", height: "25px" }, // Ändere die gewünschte Breite und Höhe
                  })}
             
                </div>     
              )}
            </div>
          ))}
        </div>
        {/* <h1 style={{ ...styles.normalText, flex: "1" }}>
        
            
            <a
              label="Instagram"
              href="https://www.instagram.com/hempheavenhannover?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
              target="_blank"
              rel="noopener noreferrer"
            >
              Insta
            </a>
          <a
            href="/"
            title="Informationen über die Anbauvereinigung"
            style={styles.normalText}
          >
            Anbauvereinigung
          </a>
        </h1>
        <h1 style={{ ...styles.normalText, flex: "1" }}>
          <a
            href="/Beitraege"
            title="Informationen über die Beiträge"
            style={styles.normalText}
          >
            Beiträge
          </a>
        </h1>
        <h1 style={{ ...styles.normalText, flex: "1" }}>
          <a
            href="/Vereinskodex"
            title="Unser Vereinskodex"
            style={styles.normalText}
          >
            Vereinskodex
          </a>
        </h1>
        <h1 style={{ ...styles.normalText, flex: "1" }}>
          <a
            href="/Vereinsdokumente"
            title="Unsere Vereinsdokumente"
            style={styles.normalText}
          >
            Vereinsdokumente
          </a>
        </h1>
        <h1 style={{ ...styles.normalText, flex: "1" }}>
          <a
            href="/Gesundheit"
            title="Aufklärung über Cannabis"
            style={styles.normalText}
          >
            Gesundheit & Prävention
          </a>
        </h1>
        <h1 style={{ ...styles.normalText, flex: "1" }}>
          <a
            href="/Mitgliedwerden"
            title="Informationen über den Vorgang zur Mitgliedschaft"
            style={styles.normalText}
          >
            Mitglied werden
          </a>
        </h1>
        <h1 style={{ ...styles.normalText, flex: "1" }}>
          <a
            href="/Galerie"
            title="Galerie mit Bildern vom Verein"
            style={styles.normalText}
          >
            Galerie
          </a>
        </h1> */}
      </Box>
    </div>
  );
};

export default Linkleiste;
