import * as React from "react";
import {supabase} from "../../../config/supabaseClient.js";
import { useState, useEffect } from "react";

import Button from "@mui/material/Button";
import {
  FormControl,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";

export default function ParticipateInMiscSurveyDialog(props) {
  const { UserData } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [surveyInfo, setSurveyInfo] = useState(null);
  const [answers, setAnswers] = useState({});
  const [participated, setParticipated] = useState(false);
  const [wrongRole, setWrongRole] = useState(false);

  useEffect(() => {
    if (UserData[0].role === "Registriert" || UserData[0].role === "Bann") {
      setWrongRole(true);
    } else {
      setWrongRole(false);
    }
  }, []);

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    const MemberParticipated = async () => {
      try {
        const { data: latestSurvey, error: latestSurveyError } = await supabase
          .from("misc")
          .select("*")
          .eq("keyword", "Sonstige Umfrage")
          .order("created_at", { ascending: false });

        if (!latestSurvey || latestSurvey.length === 0) {
          return true;
        }

        const participantsID = latestSurvey[1].content?.ParticipantsID;

        if (participantsID && participantsID.includes(UserData[0].id)) {
          setParticipated(true);
          return true;
        } else {
          setParticipated(false);
          return false;
        }
      } catch (error) {
        console.error("Fehler beim Abrufen der Umfrage:", error);
        return false;
      }
    };
    MemberParticipated();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: surveys, error } = await supabase
          .from("misc")
          .select("id, keyword, content")
          .eq("keyword", "Sonstige Umfrage")
          .order("created_at", { ascending: false });

        if (error) {
          console.error("Fehler beim Abrufen der Umfragen:", error);
          return;
        }

        if (surveys && surveys.length >= 2) {
          const survey = surveys[1]; // Die vorletzte Umfrage auswählen (Index 1)
          const surveyTitle = `${survey.keyword} ${survey.content.Umfragestart} - ${survey.content.Umfrageende}`;

          setSurveyInfo({
            surveyTitle,
            surveyData: survey.content,
            questions: survey.content.Fragen,
          });
        } else {
          console.error("Es gibt nicht genügend Umfragen vorhanden.");
        }
      } catch (error) {
        console.error("Fehler beim Abrufen der Umfragedaten:", error);
      }
    };

    fetchData();
  }, []);

  const handleAnswerChange = (questionIndex, selectedQuestion, selectedValue) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [`Frage ${questionIndex + 1}`]: selectedValue,
    }));
  };

  const handleParticipateInSurvey = async () => {
    try {
      const { data: surveys } = await supabase
        .from("misc")
        .select("*")
        .eq("keyword", "Sonstige Umfrage")
        .order("created_at", { ascending: false });

      if (surveys && surveys.length >= 2) {
        const latestSurvey = surveys[1];
        const currentContent = latestSurvey.content;
        const updatedContent = { ...currentContent };

        for (let question = 0; question < updatedContent.Fragen.length; question++) {
          const questionKey = `Frage ${question + 1}`;

          if (answers[questionKey]) {
            const answerValue = answers[questionKey];

            for (const option in updatedContent.Fragen[question].Auswahlmoeglichkeiten) {
              const optionKey = Object.keys(updatedContent.Fragen[question].Auswahlmoeglichkeiten[option])[0];

              if (answerValue === optionKey) {
                updatedContent.Fragen[question].Auswahlmoeglichkeiten[option][optionKey] += 1;
              }

              if (!updatedContent.ParticipantsID.includes(UserData[0]?.id)) {
                updatedContent.ParticipantsID.push(UserData[0]?.id);
              }
            }
          }
        }

        await supabase
          .from("misc")
          .update([{ content: updatedContent }])
          .eq("id", latestSurvey.id);

        setParticipated(true);
      }
    } catch (error) {
      console.error("Fehler beim Teilnehmen an der Umfrage:", error);
    }

    handleCloseDialog();
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        disabled={participated || wrongRole}
        sx={{ margin: 1 }}
        color="success"
      >
        Teilnehmen Umfrage 1
      </Button>
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>{surveyInfo?.surveyTitle}</DialogTitle>
        <DialogContent>
          {surveyInfo &&
            surveyInfo.questions &&
            surveyInfo.questions.length > 0 ? (
              surveyInfo.questions.map((question, questionIndex) => (
                <FormControl key={questionIndex} fullWidth sx={{ marginY: 2 }}>
                  <Typography variant="h6">{`Frage ${questionIndex + 1}: ${
                    Object.values(question)[0]
                  }`}</Typography>
                  <Select
                    value={answers[`Frage ${questionIndex + 1}`] || ""}
                    onChange={(e) =>
                      handleAnswerChange(
                        questionIndex,
                        Object.keys(question)[0],
                        e.target.value
                      )
                    }
                    displayEmpty
                    required
                  >
                    <MenuItem disabled value="">
                      Bitte auswählen
                    </MenuItem>
                    {question.Auswahlmoeglichkeiten &&
                      question.Auswahlmoeglichkeiten.map((option, optionIndex) => (
                        <MenuItem key={optionIndex} value={Object.keys(option)[0]}>
                          {Object.keys(option)[0]}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              ))
            ) : (
              <Typography>Umfragedaten werden geladen...</Typography>
            )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Schließen</Button>
          <Button onClick={handleParticipateInSurvey}>Absenden</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
