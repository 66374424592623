import * as React from "react";
import {supabase} from "../../../config/supabaseClient.js";
import { useState, useEffect } from "react";

import Button from "@mui/material/Button";
import {
  FormControl,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";

export default function ParticipateInMiscSurveyDialog3(props) {
  const { UserData } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [surveyInfo, setSurveyInfo] = useState(null);
  const [answers, setAnswers] = useState({});
  const [participated, setParticipated] = useState(false);
  const [wrongRole, setWrongRole] = useState(false);

  useEffect(() => {
    if (UserData[0].role === "Registriert" || UserData[0].role === "Bann") {
      //console.log("1",UserData[0].role, "Falsche Rolle? ", wrongRole);
      setWrongRole(true);
    } else {
      setWrongRole(false);
      //console.log("2",UserData[0].role, "Falsche Rolle? ", wrongRole);
    }
  }, []);

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    const MemberParticipated = async () => {
      try {
        const { data: latestSurvey, error: latestSurveyError } = await supabase
          .from("misc")
          .select("*")
          .eq("keyword", "Sonstige Umfrage")
          .order("created_at", { ascending: false })
          .limit(2);

        if (!latestSurvey || latestSurvey.length === 0) {
          return true;
        }

        const participantsID = latestSurvey[0].content?.ParticipantsID;

        if (participantsID && participantsID.includes(UserData[0].id)) {
          setParticipated(true);
          return true;
        } else {
          setParticipated(false);
          return false;
        }
      } catch (error) {
        console.error("Fehler beim Abrufen der Umfrage:", error);
        return false;
      }
    };
    MemberParticipated();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getSurveyData();
      setSurveyInfo(result);
    };

    fetchData();
  }, []);

  const getSurveyData = async () => {
    try {
      const { data: latestSurvey } = await supabase
        .from("misc")
        .select("id, keyword, content")
        .eq("keyword", "Sonstige Umfrage")
        .order("created_at", { ascending: false })
        .limit(1);

      if (!latestSurvey || latestSurvey.length === 0) {
        return null;
      }

      const survey = latestSurvey[0];
      const surveyTitle = `${survey.keyword} ${survey.content.Umfragestart} - ${survey.content.Umfrageende}`;

      return {
        surveyTitle,
        surveyData: survey.content,
        questions: survey.content.Fragen,
      };
    } catch (error) {
      console.error("Fehler beim Abrufen der Umfragedaten:", error);
      return null;
    }
  };

  const handleAnswerChange = (
    questionIndex,
    selectedQuestion,
    selectedValue
  ) => {
    //console.log(`Vor der Änderung: Frage ${questionIndex + 1} - Vorherige Auswahl: ${answers[questionIndex] || "Keine Auswahl"}`);
    //console.log("selectedQuestion: ", selectedQuestion , "questionIndex: ", questionIndex)
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [`Frage ${questionIndex + 1}`]: selectedValue, //selectedQuestion oder questionIndex. die Abfrage absenden funktioniert mit selectedQuestion und die richtige ansicht funktioniert mit questionIndex
    }));
    //console.log(`Nach der Änderung: Frage ${questionIndex + 1} - Jetzige Auswahl: ${selectedValue || "Keine Auswahl"}`);
    //console.log("Answers", answers);
    //console.log(answers?.[`Frage ${questionIndex + 1}`]);
  };

  const handleParticipateInSurvey = async () => {
    try {
      const { data: latestSurvey } = await supabase
        .from("misc")
        .select("*")
        .eq("keyword", "Sonstige Umfrage")
        .order("created_at", { ascending: false })
        .limit(1);

      if (latestSurvey && latestSurvey.length > 0) {
        const currentContent = latestSurvey[0].content;
        const updatedContent = { ...currentContent };
        //console.log("updatedContent: ", updatedContent);

        // Iteriere über die Fragen in updatedContent
        for (
          let question = 0;
          question < updatedContent.Fragen.length;
          question++
        ) {
          const questionKey = `Frage ${question + 1}`;
          //console.log("questionKey",questionKey)
          // Überprüfe, ob die Frage in den Antworten existiert
          if (answers[questionKey]) {
            const answerValue = answers[questionKey];
            //console.log("answerValue",answers[questionKey])

            // Iteriere über die Auswahlmöglichkeiten für die Frage
            for (const option in updatedContent.Fragen[question]
              .Auswahlmoeglichkeiten) {
              const optionKey = Object.keys(
                updatedContent.Fragen[question].Auswahlmoeglichkeiten[option]
              )[0];

              // Überprüfe, ob die Auswahlmöglichkeit in den Antworten existiert
              if (answerValue === optionKey) {
                // Erhöhe den Wert in updatedContent um 1
                updatedContent.Fragen[question].Auswahlmoeglichkeiten[option][
                  optionKey
                ] += 1;
              }

              if (!updatedContent.ParticipantsID.includes(UserData[0]?.id)) {
                updatedContent.ParticipantsID.push(UserData[0]?.id);
              }
            }
          }
        }

        // Aktualisiere die Umfrage-Daten in der Datenbank
        await supabase
          .from("misc")
          .update([{ content: updatedContent }])
          .eq("id", latestSurvey[0].id);

        //console.log(updatedContent);
        // Setze den Teilnahme-Status auf true
        setParticipated(true);
      }
    } catch (error) {
      console.error("Fehler beim Teilnehmen an der Umfrage:", error);
    }

    handleCloseDialog();
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        disabled={participated || wrongRole}
        sx={{ margin: 1 }}
        color="success"
      >
        Teilnehmen Umfrage 2
      </Button>
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>{surveyInfo?.surveyTitle}</DialogTitle>
        <DialogContent>
          {surveyInfo &&
          surveyInfo.questions &&
          surveyInfo.questions.length > 0 ? (
            surveyInfo.questions.map((question, questionIndex) => (
              <FormControl key={questionIndex} fullWidth sx={{ marginY: 2 }}>
                <Typography variant="h6">{`Frage ${questionIndex + 1}: ${
                  Object.values(question)[0]
                }`}</Typography>
                <Select
                  value={answers[`Frage ${questionIndex + 1}`] || ""}
                  onChange={(e) =>
                    handleAnswerChange(
                      questionIndex,
                      Object.keys(question)[0], // Hier selectedQuestion übergeben
                      e.target.value
                    )
                  }
                  displayEmpty
                  required
                >
                  <MenuItem disabled value="">
                    Bitte auswählen
                  </MenuItem>
                  {question.Auswahlmoeglichkeiten &&
                    question.Auswahlmoeglichkeiten.map(
                      (option, optionIndex) => (
                        <MenuItem
                          key={optionIndex}
                          value={Object.keys(option)[0]}
                        >
                          {Object.keys(option)[0]}
                        </MenuItem>
                      )
                    )}
                </Select>
              </FormControl>
            ))
          ) : (
            <Typography>Umfragedaten werden geladen...</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Schließen</Button>
          <Button onClick={handleParticipateInSurvey}>Absenden</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
