import { useState, useRef, useEffect } from "react";
import { Text } from "react-native";
import "../../App.css";
import {supabase} from "../../config/supabaseClient";
import { differenceInYears } from "date-fns";
import { TextField, capitalize } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
//import { DateField } from "@mui/x-date-pickers/DateField";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import FormHelperText from "@mui/material/FormHelperText";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import Checkbox from "@mui/material/Checkbox";
import dayjs from "dayjs";
import { styles } from "../../styles";
import Tooltip from "@mui/material/Tooltip";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const FIRSTNAME_REGEX = /[\p{L}][\p{L}0-9-_]{2,23}$/u;
const LASTNAME_REGEX = /[\p{L}][\p{L}0-9-_]{2,23}$/u; //muss mit einem groß oder kleinbuchstaben von a bis z starten und dann mit mindestens 3 weiteren zeichen folgen von a bis _
const DOB_REGEX = /^(?:1[01][0-9]|100|1[8-9]|[2-9][0-9])$/; //Anmeldung möglich von 18-120
const STREET_REGEX = /[\p{L}][\p{L}0-9-_.]{2,40}$/u;
const NUMBER_REGEX = /^\d{1,3}$/;
const NUMBERADD_REGEX = /^[a-zA-Z]{1}$/;
const PLZ_REGEX = /^\d{5}$/;
const CITY_REGEX = /[\p{L}]+$/u;
const MAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const PHONE_REGEX = /^[\+]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/;
const PID_REGEX = /(?=.*[a-zA-Z])(?=.*[0-9]).{9}$/;
const PWD_REGEX = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const SignUp = () => {
  const [firstname, setFirstName] = useState("");
  const [validFirstName, setValidFirstName] = useState(false);
  const [FirstNameFocus, setFirstNameFocus] = useState(false);

  const [lastname, setLastName] = useState("");
  const [validLastName, setValidLastName] = useState(false);
  const [LastNameFocus, setLastNameFocus] = useState(false);

  const [dob, setDOB] = useState("");
  const [validDOB, setValidDOB] = useState(false);
  const [DOBFocus, setDOBFocus] = useState(false);
  const [age, setAge] = useState("");

  const [street, setStreet] = useState("");
  const [validStreet, setValidStreet] = useState(false);
  const [StreetFocus, setStreetFocus] = useState(false);

  const [housenumber, setNumber] = useState("");
  const [validNumber, setValidNumber] = useState(false);
  const [NumberFocus, setNumberFocus] = useState(false);

  const [housenumberadd, setNumberAdd] = useState("");
  const [validNumberAdd, setValidNumberAdd] = useState(false);
  const [NumberAddFocus, setNumberAddFocus] = useState(false);

  const [plz, setPLZ] = useState("");
  const [validPLZ, setValidPLZ] = useState(false);
  const [PLZFocus, setPLZFocus] = useState(false);

  const [city, setCity] = useState("");
  const [validCity, setValidCity] = useState(false);
  const [CityFocus, setCityFocus] = useState(false);

  const [pid, setPID] = useState("");
  const [validPID, setValidPID] = useState(false);
  const [PIDFocus, setPIDFocus] = useState(false);

  const [email, setEMail] = useState("");
  const [validEMail, setValidEMail] = useState(false);
  const [EMailFocus, setEMailFocus] = useState(false);

  const [phonenr, setPhoneNr] = useState("");
  const [validPhoneNr, setValidPhoneNr] = useState(false);
  const [PhoneNrFocus, setPhoneNrFocus] = useState(false);

  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [PasswordFocus, setPasswordFocus] = useState(false);

  const [data_security_terms_accepted, setDataSecurityTermsAccepted] =
    useState(false);

  const [matchpassword, setMatchPassword] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const userRef = useRef();
  const errRef = useRef();

  const [tooltipText, setTooltipText] = useState("");

  useEffect(() => {
    // Update tooltip text based on validation

    let text = "";
    if (
      !validFirstName ||
      !validLastName ||
      !validPassword ||
      !validMatch ||
      !validDOB ||
      !validStreet ||
      !validPLZ ||
      !validEMail
    )
      text += "Deine Eingabe ist falsch bei ";
    if (!validFirstName) text += "Vorname, ";
    if (!validLastName) text += "Nachname, ";
    if (!validPassword) text += "Passwort, ";
    if (!validMatch) text += "Passwort-Bestätigung, ";
    if (!validDOB) text += "Geburtsdatum, ";
    if (!validStreet) text += "Straße, ";
    if (!validNumber) text += "Hausnummer, ";
    if (!validPLZ) text += "Postleitzahl, ";
    if (housenumberadd !== "" && !validNumberAdd) text += "Hausnummer-Zusatz, ";
    //if (!validPID ) text += "Personalausweisnummer, ";validNumberAdd
    if (!validEMail) text += "E-Mail Adresse ";
    if (!data_security_terms_accepted)
      text += "Zustimmung der Datenschutzerklärung ";
    // Add similar conditions for other fields
    setTooltipText(text);
    //console.log(tooltipText);
  }, [
    validFirstName,
    validLastName,
    validPassword,
    validMatch,
    validDOB,
    validStreet,
    validNumber,
    validPLZ,
    validEMail,
    validPID,
    data_security_terms_accepted,
  ]); // Add dependencies for other fields as needed

  useEffect(() => {
    const result = FIRSTNAME_REGEX.test(firstname);
    //console.log("Firstname: " + firstname + " " + result);
    //console.log(firstname)
    setValidFirstName(result);
  }, [firstname]);

  useEffect(() => {
    const result = LASTNAME_REGEX.test(lastname);
    //console.log("Lastname: " + lastname + " " + result);
    //console.log(lastname)
    setValidLastName(result);
  }, [lastname]);

  /*   useEffect(() => {
    const today = new Date(); //https://stackoverflow.com/questions/66470624/date-fns-format-date-and-age-calculation-problem-in-react
    const date = new Date(dob);
    setAge(differenceInYears(today, date));
    const result = DOB_REGEX.test(age);
    console.log("DOB: " + age + " " + result + " Date: " + date);
    setValidDOB(result);
  }, [dob, age]); */
  useEffect(() => {
    const today = dayjs();
    const date = dayjs(dob, "DD.MM.YYYY");
    const result = differenceInYears(today.toDate(), date.toDate());
    //console.log("DOB: " + result + " Date: " + date);
    setAge(result);
    setValidDOB(DOB_REGEX.test(result.toString()));
    //console.log(validDOB)
  }, [dob]);

  useEffect(() => {
    const result = STREET_REGEX.test(street);
    //console.log("street: " + street + " " + result);
    //console.log(street)
    setValidStreet(result);
  }, [street]);

  useEffect(() => {
    const result = NUMBER_REGEX.test(housenumber);
    //console.log("housenumber: " + housenumber + " " + result);
    //console.log(housenumber)
    setValidNumber(result);
  }, [housenumber]);

  useEffect(() => {
    const result = NUMBERADD_REGEX.test(housenumberadd);
    //console.log("housenumber zusatz: " + housenumberadd + " " + result);
    //console.log(housenumberadd)
    setValidNumberAdd(result);
  }, [housenumberadd]);

  useEffect(() => {
    const result = PLZ_REGEX.test(plz);
    //console.log("plz: " + plz + " " + result);
    //console.log(plz)
    setValidPLZ(result);
  }, [plz]);

  useEffect(() => {
    const result = CITY_REGEX.test(city);
    //console.log("city: " + city + " " + result);
    //console.log(city)
    setValidCity(result);
  }, [city]);

  /*   useEffect(() => {
    const result = PID_REGEX.test(pid);
    //console.log("PID: " + pid + " " + result);
    //console.log(pid)
    setValidPID(result);
  }, [pid]); */

  useEffect(() => {
    const result = MAIL_REGEX.test(email);
    //console.log("Email: " + email + " " + result);
    //console.log(email)
    setValidEMail(result);
  }, [email]);

  useEffect(() => {
    const result = PHONE_REGEX.test(phonenr);
    //console.log("Tel.: " + phonenr + " " + result);
    //console.log(phonenr)
    setValidPhoneNr(result);
  }, [phonenr]);

  useEffect(() => {
    const result = PWD_REGEX.test(password);
    //console.log("Password: " + password + " " + result);
    //console.log(password)
    setValidPassword(result);
    const match = password === matchpassword;
    setValidMatch(match);
  }, [password, matchpassword]);

  useEffect(() => {
    // Funktion zum Aktualisieren von data_security_terms_accepted
    const updateDataSecurityTermsAccepted = () => {
      setDataSecurityTermsAccepted((prev) => !prev); // Invertiere den vorherigen Wert
    };

    // Füge einen Eventlistener zur Checkbox hinzu
    const checkbox = document.getElementById("dataSecurityCheckbox");
    checkbox.addEventListener("change", updateDataSecurityTermsAccepted);

    // Entferne den Eventlistener beim Aufräumen
    return () => {
      checkbox.removeEventListener("change", updateDataSecurityTermsAccepted);
    };
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [firstname, lastname, password, matchpassword]);

  useEffect(() => {
    userRef.current.focus();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const v1 = FIRSTNAME_REGEX.test(firstname);
    const v2 = PWD_REGEX.test(password);
    const v3 = LASTNAME_REGEX.test(lastname);
    const v4 = DOB_REGEX.test(age); //war vorher aus kommentiert 08.11.2023
    const v5 = STREET_REGEX.test(street);
    const v6 = NUMBER_REGEX.test(housenumber);
    const v7 = PLZ_REGEX.test(plz);
    const v8 = CITY_REGEX.test(city);
    //const v9 = PID_REGEX.test(pid);
    const v10 = MAIL_REGEX.test(email);
    const v11 =
      housenumberadd === "" ? true : NUMBERADD_REGEX.test(housenumberadd);
    if (
      !v1 ||
      !v2 ||
      !v3 ||
      !v4 ||
      !v5 ||
      !v6 ||
      !v7 ||
      !v8 ||
      //!v9 ||
      !v10 ||
      !v11
    ) {
      setErrMsg("Ungültige Eingabe");
      console.log("das war nichts!");
      console.log(
        FIRSTNAME_REGEX.test(firstname),
        PWD_REGEX.test(password),
        LASTNAME_REGEX.test(lastname),
        DOB_REGEX.test(age),
        STREET_REGEX.test(street),
        NUMBER_REGEX.test(housenumber),
        NUMBERADD_REGEX.test(housenumberadd),
        PLZ_REGEX.test(plz),
        CITY_REGEX.test(city),
        /* PID_REGEX.test(pid), */
        MAIL_REGEX.test(email)
      );
      return;
    }
    const formData = {
      firstname: firstname,
      lastname: lastname,
      dob: dob,
      street: street,
      housenumber: housenumber,
      housenumberadd: housenumberadd,
      plz: plz,
      city: city,
      email: email,
      phonenr: phonenr,
      /* pid: pid, */
      password: password,
    };
    //console.log(formData);

    try {
      const { data, insertError } = await supabase.from("User").insert([
        {
          firstname: formData.firstname,
          lastname: formData.lastname,
          email: formData.email,
          date_of_birth: formData.dob,
          role: "Registriert",
          street: formData.street,
          housenumber: formData.housenumber,
          housenumber_add: formData.housenumberadd,
          zip_code: formData.plz,
          city: formData.city,
          phone_nr: formData.phonenr,
          model: "",
          current_demand: 0,
          last_change_demand: "",
          special_contribution: 0,
          demand_next_month: 0,
          /* passport_ID: formData.pid, */
        },
      ]);
      const { user, error } = await supabase.auth.signUp({
        email: formData.email,
        password: formData.password,
      });

      if (error) {
        throw error;
        setSuccess(false);
      }

      if (insertError) {
        throw insertError;
        setSuccess(false);
      }

      alert("Check your email for the verification link");
      setSuccess(true);
    } catch (error) {
      alert(error.message);
      setSuccess(false);
    }
  };

  return (
    <>
    <HelmetProvider>
      {success ? (
        <Box sx={{ margin: "auto", marginTop: 5, textAlign: "center" }}>
          <Text style={styles.titleTextWhite}>
            <span>Erfolgreich registriert! </span>
            {"\n"}
            <span style={styles.normalTextWhite}>
              Du solltest eine E-Mail erhalten haben, überprüfe auch deinen
              Spam-Ordner.
            </span>
          </Text>
        </Box>
      ) : (
        <div>
        <Helmet>
          <title>
            Registrierung der Anbauvereinigung Hemp Heaven Hannover
          </title>
          <meta
            name="description"
            content="Registriere dich bei der Anbauvereinigung Hemp Heaven Hannover um deine Mitgliedschaft zu beantragen oder um zu erfahren wie die Website weiter aufgebaut ist."
          />
          <meta
            name="keywords"
            content="Anbauvereinigung, Hannover, Cannabis, CSC, Cannabis Social Club, Marihuana, Hash, Haschisch, Dope, Weed"
          />
          </Helmet>
        <Box sx={{ margin: 5 }}>
          <Text
            ref={errRef}
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </Text>

          <form className="formcontainer" onSubmit={handleSubmit}>
            <h1
              style={styles.titleText}
              display="flex"
              justify-content="center"
              align-items="center"
            >
              Registrieren
            </h1>
            <p style={styles.boldTextRed}>
            Derzeit treten Probleme bei der Registrierung über iOS-Geräte auf. Wir empfehlen daher, für die Registrierung kein Gerät mit einem iOS-Betriebssystem (wie iPhone, iPad oder Mac) zu verwenden.
          </p>
            <FormControl fullWidth sx={{ m: 1 / 2, mt: 2 }}>
              <TextField
                label="Vorname"
                type="text"
                name="firstname"
                id="firstname"
                required
                color={validFirstName ? "success" : "error"}
                //onInput={handleChange}
                onChange={(e) => {
                  setFirstName(capitalize(e.target.value));
                }}
                inputRef={userRef}
                inputProps={{
                  startadornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                  style: { textTransform: "capitalize" },
                }} /* https://mui.com/material-ui/api/text-field/ alle felder durch mui textfield ersetzen um manipulation umzusetzen */
                onFocus={() => setFirstNameFocus(true)}
                onBlur={() => setFirstNameFocus(false)}
                helperText={
                  !FirstNameFocus
                    ? ""
                    : validFirstName
                    ? ""
                    : "3 bis 24 Zeichen. Muss mit einem Buchstaben beginnen. Buchstaben und Bindestriche sind erlaubt."
                }
              />
            </FormControl>
            <FormControl fullWidth sx={{ m: 1 / 2 }}>
              <TextField
                label="Nachname"
                type="text"
                name="lastname"
                id="lastname"
                required
                color={validLastName ? "success" : "error"}
                //onInput={handleChange}
                onChange={(e) => {
                  setLastName(capitalize(e.target.value));
                }}
                inputRef={userRef}
                inputProps={{
                  startadornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                  style: { textTransform: "capitalize" },
                }}
                onFocus={() => setLastNameFocus(true)}
                onBlur={() => setLastNameFocus(false)}
                helperText={
                  !LastNameFocus
                    ? ""
                    : validLastName
                    ? ""
                    : "3 bis 24 Zeichen. Muss mit einem Buchstaben beginnen. Buchstaben und Bindestriche sind erlaubt."
                }
              />
            </FormControl>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={["DatePicker"]}
                  sx={{ m: 1 / 2 }}
                  maxWidth={false}
                >
                  <DatePicker
                    label="Geburtsdatum"
                    slotProps={{ textField: { name: "dob", id: "dob" } }}
                    format="DD.MM.YYYY"
                    //required
                    //color={validDOB ? "success" : "error"}
                    onChange={(e) => {
                      setDOB(dayjs(e).format("DD.MM.YYYY"));
                      //console.log(dob)
                    }}
                    inputRef={userRef}
                    onFocus={() => setDOBFocus(true)}
                    onBlur={() => setDOBFocus(false)}
                    helperText={
                      !DOBFocus
                        ? ""
                        : validDOB
                        ? ""
                        : "Du musst 18 Jahre alt sein um dich anmelden zu können."
                    }
                  />
                </DemoContainer>
              </LocalizationProvider>
            </FormControl>
            <div className="streetdata">
              <FormControl sx={{ m: 1 / 2, width: "32ch" }}>
                <TextField
                  label="Straße"
                  type="text"
                  name="street"
                  id="street"
                  required
                  color={validStreet ? "success" : "error"}
                  //onInput={handleChange}
                  onChange={(e) => {
                    setStreet(capitalize(e.target.value));
                  }}
                  inputRef={userRef}
                  inputProps={{
                    startadornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                    style: { textTransform: "capitalize" },
                  }}
                  onFocus={() => setStreetFocus(true)}
                  onBlur={() => setStreetFocus(false)}
                  helperText={!StreetFocus ? "" : validStreet ? "" : ""}
                />
              </FormControl>
              <FormControl sx={{ m: 1 / 2, width: "15ch" }}>
                <TextField
                  label="Hausnummer"
                  type="number"
                  name="housenumber"
                  id="housenumber"
                  required
                  color={validNumber ? "success" : "error"}
                  //onInput={handleChange}
                  onChange={(e) => {
                    setNumber(e.target.value);
                  }}
                  inputRef={userRef}
                  inputProps={{
                    startadornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  onFocus={() => setNumberFocus(true)}
                  onBlur={() => setNumberFocus(false)}
                  helperText={
                    !NumberFocus ? "" : validNumber ? "" : "Maximal 3-stellig"
                  }
                />
              </FormControl>
              <FormControl sx={{ m: 1 / 2, width: "10ch" }}>
                <TextField
                  label="Zusatz"
                  type="text"
                  name="housenumberadd"
                  id="housenumberadd"
                  color={validNumberAdd ? "success" : "error"}
                  //onInput={handleChange}
                  onChange={(e) => {
                    setNumberAdd(capitalize(e.target.value));
                  }}
                  inputRef={userRef}
                  inputProps={{
                    startadornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                    style: { textTransform: "capitalize" },
                  }}
                  onFocus={() => setNumberAddFocus(true)}
                  onBlur={() => setNumberAddFocus(false)}
                  helperText={
                    !NumberAddFocus
                      ? ""
                      : validNumberAdd
                      ? ""
                      : "Maximal 1-stellig"
                  }
                />
              </FormControl>
            </div>
            <div>
              <FormControl sx={{ m: 1 / 2, width: "15ch" }}>
                <TextField
                  label="Postleitzahl"
                  type="number"
                  name="plz"
                  id="plz"
                  required
                  color={validPLZ ? "success" : "error"}
                  //onInput={handleChange}
                  onChange={(e) => {
                    setPLZ(e.target.value);
                  }}
                  inputRef={userRef}
                  inputProps={{
                    startadornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  onFocus={() => setPLZFocus(true)}
                  onBlur={() => setPLZFocus(false)}
                  helperText={
                    !PLZFocus
                      ? ""
                      : validPLZ
                      ? ""
                      : "Die PLZ (Postleitzahl) muss 5 Stellen haben und darf nur aus Zahlen bestehen"
                  }
                />
              </FormControl>
              <FormControl sx={{ m: 1 / 2, width: "43ch" }}>
                <TextField
                  label="Wohnort"
                  type="text"
                  name="city"
                  id="city"
                  required
                  color={validCity ? "success" : "error"}
                  //onInput={handleChange}
                  onChange={(e) => {
                    setCity(capitalize(e.target.value));
                  }}
                  inputRef={userRef}
                  inputProps={{
                    startadornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                    style: { textTransform: "capitalize" },
                  }}
                  onFocus={() => setCityFocus(true)}
                  onBlur={() => setCityFocus(false)}
                  helperText={
                    !CityFocus
                      ? ""
                      : validCity
                      ? ""
                      : "Muss mindestens 3 Buchstaben besitzen"
                  }
                />
              </FormControl>
            </div>
            <FormControl fullWidth sx={{ m: 1 / 2 }}>
              <TextField
                label="E-Mail Adresse"
                type="text"
                name="email"
                id="email"
                autoComplete="username"
                required
                color={validEMail ? "success" : "error"}
                //onInput={handleChange}
                onChange={(e) => {
                  setEMail(e.target.value);
                }}
                inputRef={userRef}
                inputProps={{
                  startadornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                onFocus={() => setEMailFocus(true)}
                onBlur={() => setEMailFocus(false)}
                helperText={
                  !EMailFocus
                    ? ""
                    : validEMail
                    ? ""
                    : "Muss mindestens 6 Zeichen besitzen. Muss ein @ und einen . (Punkt) enthalten."
                }
              />
            </FormControl>
            <FormControl fullWidth sx={{ m: 1 / 2 }}>
              <TextField
                label="Telefonnummer"
                type="number"
                name="phonenr"
                id="phonenr"
                color={validPhoneNr ? "success" : "error"}
                //onInput={handleChange}
                onChange={(e) => {
                  setPhoneNr(e.target.value);
                }}
                inputRef={userRef}
                inputProps={{
                  startadornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                onFocus={() => setPhoneNrFocus(true)}
                onBlur={() => setPhoneNrFocus(false)}
                helperText={
                  !PhoneNrFocus
                    ? ""
                    : validPhoneNr
                    ? ""
                    : "Nur Nummern sind erlaubt."
                }
              />
            </FormControl>
            <FormControl fullWidth sx={{ m: 1 / 2 }}>
              <InputLabel htmlFor="outlined-adornment-password">
                Passwort
              </InputLabel>
              <OutlinedInput
                label="Passwort"
                id="password"
                name="password"
                autoComplete="current-password"
                type={showPassword ? "text" : "password"}
                color={validPassword ? "success" : "error"}
                required
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                inputRef={userRef}
                onFocus={() => setPasswordFocus(true)}
                onBlur={() => setPasswordFocus(false)}
                aria-describedby="pw-helper-text"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText id="pw-helper-text">
                {!PasswordFocus
                  ? ""
                  : validPassword
                  ? ""
                  : "8 bis 24 Zeichen. Muss einen Groß- und Kleinbuchstaben, eine Nummer und ein Sonderzeichen enthalten. Erlaubte Sonderzeichen sind: !, @, #, $, %"}
              </FormHelperText>
            </FormControl>
            <FormControl fullWidth sx={{ m: 1 / 2 }}>
              <InputLabel htmlFor="outlined-adornment-password">
                Passwort bestätigen
              </InputLabel>
              <OutlinedInput
                label="Passwort bestätigen"
                id="matchpassword"
                name="matchpassword"
                autoComplete="current-password"
                type={showPassword ? "text" : "password"}
                color={validMatch ? "success" : "error"}
                required
                onChange={(e) => {
                  setMatchPassword(e.target.value);
                }}
                inputRef={userRef}
                onFocus={() => setMatchFocus(true)}
                onBlur={() => setMatchFocus(false)}
                aria-describedby="pwm-helper-text"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText id="pwm-helper-text">
                {!matchFocus
                  ? ""
                  : validMatch
                  ? ""
                  : "Die Eingabe stimmt nicht mit dem Passwort überein"}
              </FormHelperText>
            </FormControl>
            <Checkbox id="dataSecurityCheckbox" color="success" />{" "}
            <Text>
              Ich bin mit der{" "}
              <a href="/datenschutz" target="_blank" rel="noopener noreferrer">
                Datenschutzerklärung
              </a>{" "}
              einverstanden. {"\n"}
            </Text>
            <Text>
              Du hast bereits einen Account? <a title="Login" href="/login">Login</a>
            </Text>
            <br></br>
            <br></br>
            <Tooltip title={tooltipText} arrow>
              <span>
                <Button
                  type="submit"
                  variant="contained"
                  color="success"
                  disabled={
                    !validFirstName ||
                    !validLastName ||
                    !validPassword ||
                    !validMatch ||
                    !validDOB ||
                    !validStreet ||
                    !validNumber ||
                    !validPLZ ||
                    /* !validPID || */
                    !validEMail ||
                    !data_security_terms_accepted
                      ? true
                      : false
                  }
                >
                  Registrieren
                </Button>
                <Text style={styles.smallTextRed}>{"\n"}{tooltipText}</Text>
              
                </span>
            </Tooltip>
            <Text>{"\n"}
              {"\n"}Du hast Probleme bei der Registrierung? Achte darauf, dass
              keine ungewollten Leerzeichen eingegeben wurden. Solltest du dies
              überprüft haben und dich immer noch nicht registrieren können,
              kontaktiere uns mit deinen Eingaben unter
              vorstand@hemp-heaven-hannover.de.
            </Text>
          </form>
        </Box></div>
      )}
      </HelmetProvider>
    </>
  );
};
export default SignUp;
