import React from "react";
import { Text } from "react-native-web";
import { Box } from "@mui/system";
import { styles } from "../../styles";
import Paper from "@mui/material/Paper";

const AGB = () => {
  return (
    <Box
      component={Paper}
      sx={{
        margin: "auto",
        marginTop: 5,
        textAlign: "center",
        width: "60%",
        padding: 10,
      }}
    >
      {/* <Text style={styles.titleText}>
        Deine Daten werden geschützt, Trust me Bro!
      </Text> */}
      <Text>
        <span style={styles.normalText}>
          <h1>
            Allgemeine Geschäftsbedingungen (AGB) für Hemp Heaven Hannover e.V.
          </h1>
          <h2>§1 Geltungsbereich</h2>
          <p>
            (1) Diese Allgemeinen Geschäftsbedingungen (nachfolgend "AGB")
            gelten für alle über unseren Shop abgeschlossenen Verträge zwischen
            uns, Hemp Heaven Hannover e.V., Fössestraße 103, 30453 Hannover, und
            Ihnen als unseren Kunden. Die AGB gelten unabhängig davon, ob Sie
            Verbraucher, Unternehmer oder Kaufmann sind. {"\n"}(2) Alle zwischen Ihnen
            und uns im Zusammenhang mit dem Kaufvertrag getroffenen
            Vereinbarungen ergeben sich insbesondere aus diesen
            Verkaufsbedingungen, unserer schriftlichen Auftragsbestätigung und
            unserer Annahmeerklärung.
          </p>
        <h2>§2 Vertragsgegenstand</h2>
        <p>
          Dieser Vertrag regelt den Verkauf von neuen Pflanzenstecklingen über
          unseren lokalen Shop. Die Details, insbesondere die wesentlichen
          Merkmale der Ware, finden Sie in der Artikelbeschreibung und den
          ergänzenden Angaben auf unserer Website.
        </p>
        <h2>§3 Vertragsschluss</h2>
        <p>
          (1) Der Vertrag kommt zustande, sobald Sie unsere Annahmeerklärung (in
          der Regel durch eine Verkaufsbestätigung) erhalten haben, nachdem Sie
          ein verbindliches Kaufangebot abgegeben haben. {"\n"}(2) Die
          Präsentation der Waren in unserem Shop stellt kein rechtlich bindendes
          Angebot, sondern einen unverbindlichen Online-Katalog dar. Durch
          Aufgabe einer Bestellung im Shop geben Sie ein verbindliches Angebot
          zum Kauf des Produkts ab.
        </p>
        {/*         <h2>Kontakt Datenschutzbeauftragter</h2>
        <p>
          Hannes Lokatis{"\n"}
          Fössestraße 103 {"\n"}
          30453 Hannover {"\n"}
          E-Mail-Adresse: vorstand@hemp-heaven-hannover.de {"\n"}
        </p> */}
        <h2>§4 Preise und Zahlungsbedingungen</h2>
        <p>
          (1) Die angegebenen Preise sind Endpreise, die nach §19 UStG nicht die
          gesetzliche Mehrwertsteuer enthalten. {"\n"}
          (2) Die Zahlung des Kaufpreises ist unmittelbar mit Vertragsschluss
          fällig. Die Zahlung kann nach Ihrer Wahl per EC-Karte, Kreditkarte,
          Google Pay und Apple Pay erfolgen.
        </p>
        <h2>§5 Lieferung, Warenverfügbarkeit </h2>
        <p>
          (1) Sind zum Zeitpunkt der Bestellung die ausgewählten Produkte nicht
          verfügbar, so teilen wir Ihnen dies unverzüglich in der
          Auftragsbestätigung mit. Ist das Produkt dauerhaft nicht lieferbar,
          sehen wir von einer Annahmeerklärung ab. Ein Vertrag kommt in diesem
          Fall nicht zustande.{"\n"}(2) Eine Lieferung der Produkte ist nicht
          möglich. Die Produkte müssen lokal im Shop abgeholt werden.
        </p>
        <h2>§6 Ausschluss des Widerrufsrechts</h2>
        <p>
          Das Widerrufsrecht besteht nicht bei Verträgen zum Verkauf von Waren,
          die schnell verderben können oder deren Verfallsdatum schnell
          überschritten würde. Dies trifft auf alle unsere Pflanzenstecklinge
          zu, da diese schnell verderbliche Waren sind und somit vom Widerruf
          ausgeschlossen sind.
        </p>
        <h2>§7 Gewährleistung</h2>
        <p>
          Die Gewährleistung richtet sich nach den gesetzlichen Bestimmungen.
        </p>
        <h2>§8 Schlussbestimmungen </h2>
        <p>
          (1) Auf Verträge zwischen Ihnen und uns findet das Recht der
          Bundesrepublik Deutschland Anwendung unter Ausschluss des
          UN-Kaufrechts. {"\n"}(2) Sind Sie Kaufmann, juristische Person des
          öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen, ist
          Gerichtsstand für alle Streitigkeiten aus Vertragsverhältnissen
          zwischen Ihnen und uns der Sitz unseres Unternehmens.
        </p>
        </span>
      </Text>
    </Box>
  );
};

export default AGB;
