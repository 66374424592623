import { createClient } from '@supabase/supabase-js';

// Client für das erste Supabase-Projekt
export const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_ANON_KEY
);

// Client für das zweite Supabase-Projekt
export const supabase2 = createClient(
  process.env.REACT_APP_SUPABASE_URL2,
  process.env.REACT_APP_ANON_KEY2
);
