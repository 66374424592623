//Basics
//import {useTable} from 'react-table'; //https://www.youtube.com/watch?v=A9oUTEP-Q84
import * as React from "react";
import {supabase} from "../../config/supabaseClient";
import "../../App.css";
import { styles } from "../../styles";
import { useState, useEffect } from "react";
import { View, Text } from "react-native";
import { format } from "date-fns";
import dayjs from "dayjs";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";

//comps
import Mitgliedsantrag from "../../pdf/Mitgliedsantrag2.0_final.pdf";
import membershipApplication from "../../pdf/membershipApplication.pdf";
import ChangeProfileRequestDialog from "./components/ChangeProfileRequestDialog";
import ParticipateInVarietySurveyDialog from "./components/ParticipateInVarietySurveyDialog";
import ParticipateInVarietySurveyDialogHybrid from "./components/ParticipateInVarietySurveyDialogHybrid";
import ParticipateInVarietySurveyDialogIndica from "./components/ParticipateInVarietySurveyDialogIndica";
import ParticipateInVarietySurveyDialogSativa from "./components/ParticipateInVarietySurveyDialogSativa";
import ParticipateInRatingSurveyDialog from "./components/ParticipateInRatingSurveyDialog";
import ParticipateInMiscSurveyDialog from "./components/ParticipateInMiscSurveyDialog2";
import ParticipateInMiscSurveyDialog3 from "./components/ParticipateInMiscSurveyDialog3";
import GraphDemandHistory from "./components/GraphDemandHistory";
import ProfileTable from "./components/ProfileTable";
import OpeningTimes from "./components/OpeningTimes";

//MUI
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
//import TableHead from '@mui/material/TableHead';
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
//import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'
import Button from "@mui/material/Button";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import Tooltip from "@mui/material/Tooltip";
//import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { Stack, Box } from "@mui/material";
import { width } from "@mui/system";

const Profile = /* async */ (props) => {
  const { setToken, token, UserData, setUserData } = props;
  //const [UserData, setUserData] = useState(null);
  const [fetchError, setFetchError] = useState(null);
  const [news, setNews] = useState(null);
  //console.log(token)
  const [tokenAvailable, setTokenAvailable] = useState(false);
  const isScreenSmall = useMediaQuery("(max-width:1160px)");
  const navigate = useNavigate();

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      const parsedToken = JSON.parse(storedToken);
      setToken(parsedToken);
      setTokenAvailable(true); // Token ist jetzt verfügbar
    }
  }, []);

  useEffect(() => {
    // Überprüfe, ob der Token vorhanden und gleich dem aktuellen Token ist
    if (tokenAvailable && token) {
      const storedToken = localStorage.getItem("token");
      const restoredToken = storedToken ? JSON.parse(storedToken) : null;

      if (restoredToken && restoredToken === token) {
        // Verwende den wiederhergestellten Token
        // Hier kannst du möglicherweise auch zusätzliche Überprüfungen durchführen, je nach Bedarf
        // Setze den wiederhergestellten Token als aktuellen Token
        setToken(restoredToken);
      } else {
        // Token nicht vorhanden oder nicht gleich dem aktuellen Token
        // Führe die übliche Logik für den Fall durch, dass der Token nicht verfügbar ist
      }
    }
  }, [token, tokenAvailable]);

  useEffect(() => {
    if (tokenAvailable && token && token.user && token.user.id) {
      const fetchUserData = async () => {
        try {
          if (!token || !token.user || !token.user.id) {
            console.error("Token or user data is not available.");
            return;
          }

          const { data, error } = await supabase
            .from("User")
            .select("*")
            .eq("id", token.user.id);

          //console.log("API Response:", data);
          //console.log("Token:", token.user.id);

          if (error) {
            setFetchError("Could not fetch the user data");
            console.error(error);
            return;
          }

          if (data) {
            setUserData(data);
            //console.log("UserData after setUserData:", UserData);
          }
        } catch (error) {
          console.error("Error fetching User Data:", error);
          setFetchError("Could not fetch the User Data");
        }
      };

      fetchUserData();
    }
  }, [token, tokenAvailable]);

  useEffect(() => {
    //console.log("UserData in separate useEffect:", UserData);
    //console.log("Vorname:", UserData[0].firstname);
  }, [UserData]);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const { data, error } = await supabase
          .from("misc")
          .select("*") // Alle Spalten abrufen
          .eq("keyword", "News")
          .order("created_at", { ascending: false });

        if (error) {
          setFetchError("Die Nachrichten konnten nicht abgerufen werden");
          setNews(null);
          console.error(error);
        }

        if (data) {
          //console.log("Empfangene Daten:", data);

          // Extrahiere den Wert von "message" aus der JSONB-Spalte "content"
          const formattedData = data.map((item) => {
            //console.log("Einzelnes Element:", item);
            const message = item.content ? item.content.message : null;
            //console.log("Nachricht:", message);
            return {
              created_at: item.created_at,
              message,
            };
          });

          //console.log("Formatted Data:", formattedData);

          setNews(formattedData);
          setFetchError(null);
        }
      } catch (error) {
        console.error("Fehler beim Abrufen von Nachrichten:", error);
        setFetchError("Die Nachrichten konnten nicht abgerufen werden");
      }
    };

    fetchNews();
  }, []);

  const fillForm = async () => {
    // Step 1: Load the PDF form.
    const formUrl = Mitgliedsantrag;
    const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(formPdfBytes);

    // Step 2: Retrieve the form fields. (for saving space I have skipped the most of the code. Instead I've added form.getTextField('Text1'). in the next step)
    const form = pdfDoc.getForm();

    // Step 2.1: Figure out how to address the text fields
    /*     const fields = form.getFields();
  fields.forEach(field => {
    console.log(`Field name: ${field.getName()}`);
  });
alert(); */

    // Step 3: Set values for the form fields.
    const today = format(new Date(), "dd.MM.yyyy");
    form
      .getTextField("Text1")
      .setText(UserData[0].lastname + ", " + UserData[0].firstname);
    form.getTextField("Text4").setText(UserData[0].date_of_birth);
    form
      .getTextField("Text5")
      .setText(
        UserData[0].street +
          " " +
          UserData[0].housenumber +
          " " +
          UserData[0].housenumber_add
      );
    form
      .getTextField("Text6")
      .setText(UserData[0].zip_code + " " + UserData[0].city);
    form.getTextField("Text7").setText(UserData[0].email);
    /* form.getTextField("Text6").setText(UserData[0].passport_ID); */
    form.getTextField("Text8").setText(UserData[0].phone_nr);
    form.getTextField("Text9").setText("");
    /* form
      .getTextField("Text9")
      .setText(UserData[0].current_demand ? UserData[0].current_demand : ""); */
    form.getTextField("Text10").setText("Hannover, " + today);
    //form.getTextField("Text11").setText("Beste Bank test");

    //SEPA 1
    form
      .getTextField("Text11")
      .setText(UserData[0].lastname + ", " + UserData[0].firstname);
    form
      .getTextField("Text12")
      .setText(
        UserData[0].street +
          " " +
          UserData[0].housenumber +
          " " +
          UserData[0].housenumber_add
      );
    form
      .getTextField("Text13")
      .setText(UserData[0].zip_code + " " + UserData[0].city);
    form.getTextField("Text14").setText(""); //Kreditinstitut
    form.getTextField("Text15").setText(""); //BIC
    form.getTextField("Text16").setText(""); //11
    form.getTextField("Text17").setText(""); //2222
    form.getTextField("Text18").setText(""); //3333
    form.getTextField("Text19").setText(""); //4444
    form.getTextField("Text20").setText(""); //5555
    form.getTextField("Text21").setText(""); //66
    form.getTextField("Text22").setText(UserData[0].city + ", " + today);

    //SEPA 2
    form
      .getTextField("Text23")
      .setText(UserData[0].lastname + ", " + UserData[0].firstname);
    form
      .getTextField("Text24")
      .setText(
        UserData[0].street +
          " " +
          UserData[0].housenumber +
          " " +
          UserData[0].housenumber_add
      );
    form
      .getTextField("Text25")
      .setText(UserData[0].zip_code + " " + UserData[0].city);
    form.getTextField("Text26").setText(""); //Kreditinstitut
    form.getTextField("Text27").setText(""); //BIC
    form.getTextField("Text28").setText(""); //11
    form.getTextField("Text29").setText(""); //2222
    form.getTextField("Text30").setText(""); //3333
    form.getTextField("Text31").setText(""); //4444
    form.getTextField("Text32").setText(""); //5555
    form.getTextField("Text33").setText(""); //66
    form.getTextField("Text34").setText(UserData[0].city + ", " + today);

    // Step 4: Save the modified PDF.
    const pdfBytes = await pdfDoc.save();

    // Step 5: Create a `Blob` from the PDF bytes,
    const blob = new Blob([pdfBytes], { type: "application/pdf" });

    // Step 6: Create a download URL for the `Blob`.
    const url = URL.createObjectURL(blob);

    // Step 7: Create a link element and simulate a click event to trigger the download.
    const link = document.createElement("a");
    link.href = url;
    link.download = "Mitgliedsantrag.pdf";
    link.click();
  };

  const { PDFDocument } = require("pdf-lib");

  function handleLogIn(e) {
    e.preventDefault();
    navigate("/login");
  }

  function handleSignIn(e) {
    e.preventDefault();
    navigate("/signup");
  }

  return (
    <>
      {UserData && UserData.length > 0 ? (
        <div>
          {isScreenSmall ? (
            // Responsive Ansicht: Zeige Elemente nacheinander
            <Stack spacing={2} marginTop={2} marginLeft={1} marginRight={1}>
              
              <Box component={Paper} padding={1}>
                <Text style={styles.titleText} margin="auto">
                  Neuigkeiten:
                </Text>

                {UserData &&
                UserData.length > 0 &&
                UserData[0].role !== "Registriert" ? (
                  <TableContainer
                    component={Paper}
                    sx={{
                      margin: "auto",
                      marginTop: 2,
                      height: news?.length > 10 ? "500px" : "auto", // Setze eine feste Höhe, wenn mehr als 10 Einträge vorhanden sind
                      overflowY: "auto", // Füge eine Scrollbar hinzu, wenn die Höhe überschritten wird
                    }}
                  >
                    <Table aria-label="simple table">
                      <TableBody
                        sx={{
                          "&:last-child td, &:last-child th": { border: 1 },
                        }}
                      >
                        {news &&
                          news.map((newsItem) => (
                            <TableRow key={newsItem.created_at}>
                              <TableCell align="left" sx={{ width: "135px" }}>
                                {dayjs(newsItem.created_at).format(
                                  "DD.MM.YYYY"
                                )}
                              </TableCell>
                              <TableCell align="left">
                                {newsItem.message}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <>
                  <p style={styles.boldText}>
                    {" "}
                    {"\n"}Nur für Mitglieder sichtbar</p>
                    <p style={styles.boldText}>Mitglied werden in 3 Schritten!</p>
                    <p style={styles.normalText}>1. Mitgliedsantrag mit dem Button unter deinen persönlichen Daten herunterladen.</p>
                    <p style={styles.normalText}>2. Mitgliedsantrag und SEPA-Lastschriftmandat vollständig ausfüllen und unterschreiben.</p>
                    <p style={styles.normalText}>3. Beide Dokumente mit einer Kopie der Vorder- und Rückseite deines Ausweises an vorstand@hemp-heaven-hannover.de senden.
                  </p>
                  </>
                )}
              </Box>

              <Box component={Paper} padding={1}>
                <Text style={styles.titleText} margin="auto">
                  Willkommen, {UserData[0].firstname}
                </Text>

                <ProfileTable
                  UserData={UserData}
                  token={token}
                  setToken={setToken}
                />

                <Button
                  title=""
                  className="button"
                  variant="contained"
                  onClick={fillForm}
                  //disabled
                >
                  Mitgliedsantrag herunterladen
                </Button>
                {/* <Tooltip
                  title={
                    "Der Mitgliedsantrag steht zur Verfügung wenn ein Vereinskonto eröffnet ist, dafür müssen wir auf den Eintrag in das Vereinsregister durch das Amtsgericht warten. Ihr werdet per E-Mail über die Verfügbarkeit benachrichtigt."
                  }
                >
                  <HelpOutlineOutlinedIcon fontSize="small"></HelpOutlineOutlinedIcon>
                </Tooltip> */}

                <ChangeProfileRequestDialog
                  changerequest="Passwort"
                  token={token}
                  UserData={UserData}
                ></ChangeProfileRequestDialog>
              </Box>

              <Stack spacing={2} alignItems="right">
                
              <Box
                  component={Paper}
                  sx={{ padding: 1, display: "flex", flexDirection: "column" }}
                >
                  <GraphDemandHistory UserData={UserData}></GraphDemandHistory>
                </Box>
                <Box
                  component={Paper}
                  sx={{ padding: 1, display: "flex", flexDirection: "column" }}
                >
                  <Text style={styles.titleText} margin="auto">
                    Sortenumfrage:
                  </Text>

                  {/* <ParticipateInVarietySurveyDialog
                    UserData={UserData}
                  ></ParticipateInVarietySurveyDialog> */}
                  <ParticipateInVarietySurveyDialogHybrid
                    UserData={UserData}
                  ></ParticipateInVarietySurveyDialogHybrid>
                  <ParticipateInVarietySurveyDialogIndica
                    UserData={UserData}
                  ></ParticipateInVarietySurveyDialogIndica>
                  <ParticipateInVarietySurveyDialogSativa
                    UserData={UserData}
                  ></ParticipateInVarietySurveyDialogSativa>
                </Box>
                <Box
                  component={Paper}
                  sx={{ padding: 1, display: "flex", flexDirection: "column" }}
                >
                  <Text style={styles.titleText} margin="auto">
                    Bewertungsumfrage:
                  </Text>

                  <ParticipateInRatingSurveyDialog
                    UserData={UserData}
                  ></ParticipateInRatingSurveyDialog>
                </Box>
                <Box
                  component={Paper}
                  sx={{ padding: 1, display: "flex", flexDirection: "column" }}
                >
                  <Text style={styles.titleText} margin="auto">
                    Sonstige Umfrage:
                  </Text>
                  <ParticipateInMiscSurveyDialog
                    UserData={UserData}
                  ></ParticipateInMiscSurveyDialog>
                  <ParticipateInMiscSurveyDialog3
                    UserData={UserData}
                  ></ParticipateInMiscSurveyDialog3>
                </Box>
              </Stack>
              
              <OpeningTimes />
            </Stack>
          ) : (
            // Nicht-responsiver Ansicht: Zeige das Grid
            <Grid container="true" spacing={2} my={2} mx={2}>
              <Grid item="true" xs={4}>
                <Stack spacing={2}>
              <Box component={Paper} padding={1}>
                <Text style={styles.titleText} margin="auto">
                  Neuigkeiten:
                </Text>

                {UserData &&
                UserData.length > 0 &&
                UserData[0].role !== "Registriert" ? (
                  <TableContainer
                    component={Paper}
                    sx={{
                      margin: "auto",
                      marginTop: 2,
                      height: news?.length > 10 ? "500px" : "auto", // Setze eine feste Höhe, wenn mehr als 10 Einträge vorhanden sind
                      overflowY: "auto", // Füge eine Scrollbar hinzu, wenn die Höhe überschritten wird
                    }}
                  >
                    <Table aria-label="simple table">
                      <TableBody
                        sx={{
                          "&:last-child td, &:last-child th": { border: 1 },
                        }}
                      >
                        {news &&
                          news.map((newsItem) => (
                            <TableRow key={newsItem.created_at}>
                              <TableCell align="left" sx={{ width: "135px" }}>
                                {dayjs(newsItem.created_at).format(
                                  "DD.MM.YYYY"
                                )}
                              </TableCell>
                              <TableCell align="left">
                                {newsItem.message}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <>
                  <p style={styles.boldText}>
                    {" "}
                    {"\n"}Nur für Mitglieder sichtbar</p>
                    <p style={styles.boldText}>Mitglied werden in 3 Schritten!</p>
                    <p style={styles.normalText}>1. Mitgliedsantrag mit dem Button unter deinen persönlichen Daten herunterladen.</p>
                    <p style={styles.normalText}>2. Mitgliedsantrag und SEPA-Lastschriftmandat vollständig ausfüllen und unterschreiben.</p>
                    <p style={styles.normalText}>3. Beide Dokumente mit einer Kopie der Vorder- und Rückseite deines Ausweises an vorstand@hemp-heaven-hannover.de senden.
                  </p>
                  </>
                )}
              </Box>
                  <OpeningTimes />
                </Stack>
              </Grid>
              <Grid item="true" xs={4} sx={{ maxWidth: "33%"}}>
                <Box component={Paper} padding={1}>
                  <Text style={styles.titleText} margin="auto">
                    Willkommen, {UserData[0].firstname}
                  </Text>
                  <ProfileTable
                    UserData={UserData}
                    token={token}
                    setToken={setToken}
                  />

                  <Button
                    className="button"
                    variant="contained"
                    onClick={fillForm}
                    //disabled
                  >
                    Mitgliedsantrag herunterladen
                  </Button>
                  {/* <Tooltip
                    title={
                      "Der Mitgliedsantrag steht zur Verfügung wenn ein Vereinskonto eröffnet ist, dafür müssen wir auf den Eintrag in das Vereinsregister durch das Amtsgericht warten. Ihr werdet per E-Mail über die Verfügbarkeit benachrichtigt."
                    }
                  >
                    <HelpOutlineOutlinedIcon fontSize="small"></HelpOutlineOutlinedIcon>
                  </Tooltip> */}

                  <ChangeProfileRequestDialog
                    changerequest="Passwort"
                    token={token}
                    UserData={UserData}
                  ></ChangeProfileRequestDialog>
                </Box>
              </Grid>
              <Grid item="true" xs={4}>
                <Stack spacing={2} alignItems="right">
                  
                <Box
                    component={Paper}
                    sx={{
                      padding: 1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <GraphDemandHistory
                      UserData={UserData}
                    ></GraphDemandHistory>
                  </Box>
                  <Box
                    component={Paper}
                    sx={{
                      padding: 1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text style={styles.titleText} margin="auto">
                      Sortenumfrage:
                    </Text>
                    {/* <ParticipateInVarietySurveyDialog
                      UserData={UserData}
                    ></ParticipateInVarietySurveyDialog> */}
                    <ParticipateInVarietySurveyDialogHybrid
                      UserData={UserData}
                    ></ParticipateInVarietySurveyDialogHybrid>
                    <ParticipateInVarietySurveyDialogIndica
                      UserData={UserData}
                    ></ParticipateInVarietySurveyDialogIndica>
                    <ParticipateInVarietySurveyDialogSativa
                      UserData={UserData}
                    ></ParticipateInVarietySurveyDialogSativa>
                  </Box>
                  <Box
                    component={Paper}
                    sx={{
                      padding: 1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text style={styles.titleText} margin="auto">
                      Bewertungsumfrage:
                    </Text>
                    <ParticipateInRatingSurveyDialog
                      UserData={UserData}
                    ></ParticipateInRatingSurveyDialog>
                  </Box>
                  <Box
                    component={Paper}
                    sx={{
                      padding: 1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text style={styles.titleText} margin="auto">
                      Sonstige Umfrage:
                    </Text>
                    <ParticipateInMiscSurveyDialog
                      UserData={UserData}
                    ></ParticipateInMiscSurveyDialog>
                    <ParticipateInMiscSurveyDialog3
                      UserData={UserData}
                    ></ParticipateInMiscSurveyDialog3>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          )}
        </div>
      ) : (
        <Box component={Paper} padding={1}>
          <section>
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <h1 style={styles.titleText}>
                Du musst angemeldet sein, um diesen Inhalt sehen zu können.
              </h1>
              <Button
                type="submit"
                className="login"
                color="success"
                variant="contained"
                onClick={handleLogIn}
                sx={{
                  margin: "auto",
                }}
              >
                Anmelden
              </Button>
              <Button
                type="submit"
                className="signout"
                color="success"
                variant="contained"
                onClick={handleSignIn}
                sx={{
                  margin: "auto",
                  marginTop: "15px",
                }}
              >
                Registrieren
              </Button>
            </View>
          </section>
        </Box>
      )}
    </>
  );
};
export default Profile;
