import React, { useState, useEffect } from "react";

import {supabase} from "../../../config/supabaseClient";
import { Text } from "react-native-web";
import { styles } from "../../../styles";

// MUI
import { Box, Paper } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ResultRatingSurvey = () => {
  const [loading, setLoading] = useState(true); // Neue Zustandsvariable für Ladezustand
  const [allPlants, setAllPlants] = useState([]);

  const [accordionData, setAccordionData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    const getPlantsData = async () => {
      try {
        // Finde alle Pflanzen mit dem Keyword "Plant"
        const { data: allPlants, error: plantsError } = await supabase
          .from("misc")
          .select("content")
          .eq("keyword", "Plant");

        if (plantsError) {
          console.error(
            "Fehler beim Abrufen der Pflanzen:",
            plantsError.message
          );
          return null;
        }

        const allPlantnames = allPlants.map(
          (plant) => plant.content?.plantname
        );

        setAllPlants(allPlants);
      } catch (error) {
        console.error("Allgemeiner Fehler:", error.message);
        return null;
      }
    };
    getPlantsData();
  }, []);

  const SurveyGraph = async () => {
    try {
      const { data: SurveyData, error: SurveyError } = await supabase
        .from("misc")
        .select("content, created_at")
        .eq("keyword", "Bewertungsumfrage")
        .order("created_at", { ascending: false });

      if (SurveyError) {
        console.error(
          "Fehler beim Abrufen der neuesten Sortenumfrage:",
          SurveyError.message
        );
        return null;
      }

      if (SurveyData && SurveyData.length > 0) {
        // Durchlaufe alle gefundenen Umfragen
        const mappedSurveys = SurveyData.map((survey) => {
          // Extrahiere die Inhalte der Sortenumfrage
          const surveyContent = survey.content || {};

          // Entferne unwichtige Einträge
          delete surveyContent.ParticipantsIDHybrid;
          delete surveyContent.ParticipantsIDIndica;
          delete surveyContent.ParticipantsIDSativa;

          // Erstelle den Umfragetitel
          const surveyTitle = `Bewertungsumfrage ${surveyContent.Umfragestart} - ${surveyContent.Umfrageende}`;

          // Füge den Umfragetitel zum surveyContent hinzu
          surveyContent.title = surveyTitle;

          // Gib die vorbereiteten Daten zurück
          return surveyContent;
        });

        // Hier kannst du mit den vorbereiteten Daten (mappedSurveys) weiterarbeiten oder sie zurückgeben
        //console.log(mappedSurveys);
        return mappedSurveys;
      }
    } catch (error) {
      console.error("Allgemeiner Fehler:", error.message);
      setLoading(false); // Setze den Ladezustand auf false, auch im Fehlerfall
      return null;
    }
  };

  const generateAccordionData = (surveys) => {
    if (!surveys || surveys.length === 0 || !allPlants) {
      // Wenn keine gültigen Daten vorhanden sind, breche ab
      return;
    }

    const accordionItems = surveys.map((survey, index) => {
      const { title, Hybrid, TasteRatingHybrid, EffectRatingHybrid, Indica, TasteRatingIndica, EffectRatingIndica, Sativa, TasteRatingSativa, EffectRatingSativa } = survey;

      const matchingPlantHybrid = allPlants.find(
        (plant) => plant.content?.plantname === Hybrid
      );

      const tasteHybrid = matchingPlantHybrid?.content?.Taste || [];
      const effectHybrid = matchingPlantHybrid?.content?.Effect || [];

      const matchingPlantIndica = allPlants.find(
        (plant) => plant.content?.plantname === Indica
      );

      const tasteIndica = matchingPlantIndica?.content?.Taste || [];
      const effectIndica = matchingPlantIndica?.content?.Effect || [];

      const matchingPlantSativa = allPlants.find(
        (plant) => plant.content?.plantname === Sativa
      );

      const tasteSativa = matchingPlantSativa?.content?.Taste || [];
      const effectSativa = matchingPlantSativa?.content?.Effect || [];

      //console.log("Taste:", taste);
      //console.log("Taste rating:", TasteRatingHybrid);
      //console.log("Effect:", effect);
      //console.log("Effect rating:", EffectRatingHybrid);

      return (
        <Accordion key={index} sx={{ width: "100%", marginTop: 1 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index + 1}-content`}
            id={`panel${index + 1}-header`}
          >
            <Typography>{title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography style={{fontWeight: "bold", marginTop: '20px', fontSize: '1.4rem'}}>{Hybrid}</Typography>
            <Typography style={styles.boldText}>Herstellerangaben:</Typography>
            {tasteHybrid && tasteHybrid.length > 0 ? (
              <Typography>
                <span style={styles.boldText}>Geschmack:</span>{" "}
                {tasteHybrid.join(", ")}
              </Typography>
            ) : null}
            {TasteRatingHybrid ? (
              <Typography>
                <span style={styles.boldText}>Mitgliederbewertung:</span>{" "}
                {TasteRatingHybrid}<span style={styles.normalText}> / 5</span>
              </Typography>
            ) : null}
            {effectHybrid && effectHybrid.length > 0 ? (
              <Typography>
                <span style={styles.boldText}><br/>Wirkung:</span>{" "}
                {effectHybrid.join(", ")}
              </Typography>
            ) : null}
            {EffectRatingHybrid ? (
              <Typography>
                <span style={styles.boldText}>Mitgliederbewertung:</span>{" "}
                {EffectRatingHybrid}<span style={styles.normalText}> / 5</span>
              </Typography>
            ) : null}


            <Typography style={{fontWeight: "bold", marginTop: '20px', fontSize: '1.4rem'}}>{Indica}</Typography>
            <Typography style={styles.boldText}>Herstellerangaben:</Typography>
            {tasteIndica && tasteIndica.length > 0 ? (
              <Typography>
                <span style={styles.boldText}>Geschmack:</span>{" "}
                {tasteIndica.join(", ")}
              </Typography>
            ) : null}
            {TasteRatingIndica ? (
              <Typography>
                <span style={styles.boldText}>Mitgliederbewertung:</span>{" "}
                {TasteRatingIndica}<span style={styles.normalText}> / 5</span>
              </Typography>
            ) : null}
            {effectIndica && effectIndica.length > 0 ? (
              <Typography>
                <span style={styles.boldText}><br/>Wirkung:</span>{" "}
                {effectIndica.join(", ")}
              </Typography>
            ) : null}
            {EffectRatingIndica ? (
              <Typography>
                <span style={styles.boldText}>Mitgliederbewertung:</span>{" "}
                {EffectRatingIndica}<span style={styles.normalText}> / 5</span>
              </Typography>
            ) : null}


            <Typography style={{fontWeight: "bold", marginTop: '20px', fontSize: '1.4rem'}}>{Sativa}</Typography>
            <Typography style={styles.boldText}>Herstellerangaben:</Typography>
            {tasteSativa && tasteSativa.length > 0 ? (
              <Typography>
                <span style={styles.boldText}>Geschmack:</span>{" "}
                {tasteSativa.join(", ")}
              </Typography>
            ) : null}
            {TasteRatingSativa ? (
              <Typography>
                <span style={styles.boldText}>Mitgliederbewertung:</span>{" "}
                {TasteRatingSativa}<span style={styles.normalText}> / 5</span>
              </Typography>
            ) : null}
            {effectSativa && effectSativa.length > 0 ? (
              <Typography>
                <span style={styles.boldText}><br/>Wirkung:</span>{" "}
                {effectSativa.join(", ")}
              </Typography>
            ) : null}
            {EffectRatingSativa ? (
              <Typography>
                <span style={styles.boldText}>Mitgliederbewertung:</span>{" "}
                {EffectRatingSativa}<span style={styles.normalText}> / 5</span>
              </Typography>
            ) : null}
          </AccordionDetails>
        </Accordion>
      );
    });

    setAccordionData(accordionItems);
  };

  useEffect(() => {
    const fetchData = async () => {
      const surveys = await SurveyGraph();
      if (surveys) {
        generateAccordionData(surveys);
        setDataLoaded(true);
      }
    };
  
    fetchData();
  }, [allPlants]); //allPlants damit beim laden der Seite auf die Daten gewartet wird.

  return (
    <div>
      <Box
        component={Paper}
        sx={{
          padding: 2,
          paddingBottom: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxHeight: 800,
        }}
      >
        <Text style={styles.titleText} margin="auto" paddingBottom="5">
          Ergebnisse: Bewertungsumfrage
        </Text>
        {dataLoaded ? accordionData : "Keine Daten vorhanden"}
      </Box>
    </div>
  );
};

export default ResultRatingSurvey;
