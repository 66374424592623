import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {supabase} from "../../../config/supabaseClient";
import { styles } from "../../../styles";
import { Text } from "react-native";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

ChartJS.register(
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  Filler
);

const GraphDemandHistory = (props) => {
  const { UserData } = props;
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  /*   const chartData = {
    labels: ['01.01.2024','01.02.2024','01.03.2024','01.04.2024','01.05.2024','01.06.2024','01.07.2024'],
    datasets: [
      {
        label: 'Bedarfsverlauf',
        fill: true,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        tension: 0.4,
        data: [5, 7, 2, 2, 8, 4, 3],
      },
    ],
  }; */

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data, error } = await supabase
          .from("User")
          .select("demand_history")
          .eq("id", UserData[0].id);

        if (error) {
          throw error;
        }
        //console.log("data: ", data);
        if (data && data.length > 0 && data[0].demand_history) {
          const dates = Object.keys(data[0].demand_history).sort((a, b) => {
            const [dayA, monthA, yearA] = a.split(".").map(Number);
            const [dayB, monthB, yearB] = b.split(".").map(Number);

            if (yearA !== yearB) {
              return yearA - yearB;
            }

            if (monthA !== monthB) {
              return monthA - monthB;
            }

            return dayA - dayB;
          });

          // Sortiere auch die Werte entsprechend der sortierten Daten
          const sortedValues = dates.map(
            (date) => data[0].demand_history[date]
          );

          const chartsData = {
            labels: dates,
            datasets: [
              {
                label: "Bedarfsverlauf",
                fill: true,
                lineTension: 0.1,
                backgroundColor: "rgba(30,77,31,0.6)",
                borderColor: "#1e4d1f",
                borderCapStyle: "butt",
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: "#1e4d1f",
                pointBackgroundColor: "#fff",
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "#1e4d1f",
                pointHoverBorderColor: "#1e4d1f",
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: sortedValues,
              },
            ],
          };
          //console.log("chartsData: ", chartsData, "values: ", sortedValues);
          setChartData(chartsData);
        } else {
          console.warn(
            "Es liegen keine Bedarfsverlaufsdaten vor oder die Daten sind im falschen Format."
          );
          // Hier können Sie entscheiden, wie Sie mit dem Fall umgehen möchten, in dem keine Daten vorliegen.
          // Zum Beispiel könnten Sie einen Standard-Chart initialisieren oder eine Benachrichtigung anzeigen.
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <Text style={styles.titleText}>Bedarfsverlauf</Text>
      {chartData.labels.length > 0 && chartData.datasets.length > 0 && (
        <Line data={chartData} />
      )}
    </div>
  );
};

export default GraphDemandHistory;
