import React, { useState, useEffect } from "react";
import {supabase, supabase2} from "../config/supabaseClient";
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Paper,
  IconButton,
  useMediaQuery,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Helmet, HelmetProvider } from "react-helmet-async";

import OpeningTimes from "./Profile/components/OpeningTimes";

const Stecklinge = () => {
  const [plantNames, setPlantNames] = useState([]);
  const [plantDetails, setPlantDetails] = useState([]);
  const [plantCount, setPlantCount] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  // Media query, um festzustellen, ob die Bildschirmbreite unter 1600px ist
  const matches = useMediaQuery("(max-width:1600px)");

  useEffect(() => {
    fetchPlantNames();
  }, []);

  const fetchPlantNames = async () => {
    try {
      const { data, error } = await supabase2
        .from("WarehouseDispensary")
        .select("content")
        .eq("keyword", "Stecklinge");

      if (error) {
        console.error("Error fetching plant names", error);
        return;
      }

      const names = data.map((item) => item.content.plantname);
      setPlantNames(names);
      fetchPlantDetails(names);
      countPlants(data);
    } catch (error) {
      console.error("Error in fetchPlantNames", error);
    }
  };

  const fetchPlantDetails = async (names) => {
    try {
      const { data, error } = await supabase
        .from("misc")
        .select("*")
        .eq("keyword", "Plant");

      if (error) {
        console.error("Fehler beim Abrufen der Pflanzeninformationen:", error);
        return [];
      }

      // Entferne Duplikate aus den Namen
      const uniqueNames = [...new Set(names)]; // Erstellt ein Set aus Namen, das keine Duplikate enthält, und konvertiert es zurück in ein Array

      const details = uniqueNames.map((name) => {
        const matchingPlant = data.find(
          (item) => item.content && item.content.plantname === name
        );
        return matchingPlant ? matchingPlant.content : null;
      });

      setPlantDetails(details.filter(Boolean)); // Setze nur die validen, gefundenen Pflanzendetails
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching plant details", error);
      setIsLoading(false);
    }
  };

  const countPlants = (products) => {
    const count = {};
    products.forEach((product) => {
      const name = product.content.plantname;
      count[name] = (count[name] || 0) + 1;
    });
    setPlantCount(count);
  };

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (!plantDetails.length) {
    return (
      <HelmetProvider>
        <div className="page-stecklinge">
          <Helmet>
            <title>Stecklinge der Anbauvereinigung Hemp Heaven Hannover</title>
            <meta
              name="description"
              content="Informiere dich darüber welche Stecklinge derzeit im Vereinsheim verfügbar sind und wie die Öffnungszeiten der Anbauvereinigung sind."
            />
            <meta
              name="keywords"
              content="Anbauvereinigung, Hannover, Cannabis, CSC, Cannabis Social Club, Marihuana, Hash, Haschisch, Dope, Weed"
            />
          </Helmet>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            component={Paper}
            padding={4}
            width="90%"
            margin="auto"
            marginTop={5}
          >
            <Box display="flex" justifyContent="space-around" flexWrap="wrap">
              <Typography variant="h4" gutterBottom>
                Bald sind Stecklinge verfügbar
              </Typography>
            </Box>
          </Box>
        </div>
      </HelmetProvider>
    );
  }

  return (
    <>
    <HelmetProvider>
      <div className="page-stecklinge">
        <Helmet>
          <title>Stecklinge der Anbauvereinigung Hemp Heaven Hannover</title>
          <meta
            name="description"
            content="Informiere dich darüber welche Stecklinge vorhanden sind und wie die Öffnungszeiten der Anbauvereinigung sind."
          />
          <meta
            name="keywords"
            content="Anbauvereinigung, Hannover, Cannabis, CSC, Cannabis Social Club, Marihuana, Hash, Haschisch, Dope, Weed"
          />
        </Helmet>
          <Grid container="true" mx={2}>
            <Grid item="true" xs={!matches ? 4 : 12}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                component={Paper}
                padding={4}
                width="90%"
                margin="auto"
                marginTop={5}
                minHeight={!matches ? 500 : 0}
              >
                <OpeningTimes />
              </Box>
            </Grid>
            <Grid item="true" xs={!matches ? 8 : 12}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                component={Paper}
                padding={4}
                width="90%"
                margin="auto"
                marginTop={5}
                minHeight={500}
              >
                <Typography variant="h4" gutterBottom>
                  Verfügbare Stecklinge
                </Typography>

                <Box
                  display="flex"
                  justifyContent="space-around"
                  flexWrap="wrap"
                >
                  {plantDetails.map((plant, index) => (
                    <Card
                      key={index}
                      sx={{
                        maxWidth: 345,
                        height: 400,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        m: 2,
                      }}
                    >
                      <CardMedia
                        component="img"
                        sx={{ height: 140, objectFit: "contain" }}
                        image={`../../images/BudPictures/${plant.plantname.replace(
                          /\s+/g,
                          "-"
                        )}.png`}
                        alt={plant.plantname}
                      />
                      <CardContent
                        sx={{ flexGrow: 1, width: 240, height: 430 }}
                      >
                        <Typography gutterBottom variant="h5" component="div">
                          {plant.plantname}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ fontWeight: "bold" }}
                        >
                          Verfügbar: {plantCount[plant.plantname] || 0} Stück
                        </Typography>
                        <Box display="flex" alignItems="center">
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ fontWeight: "bold" }}
                          >
                            Typ:
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ marginLeft: 0.5 }}
                          >
                            {plant.type}
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ fontWeight: "bold" }}
                          >
                            THC:
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ marginLeft: 0.5 }}
                          >
                            {plant.THC}%
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ fontWeight: "bold" }}
                          >
                            CBD:
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ marginLeft: 0.5 }}
                          >
                            {plant.CBD}
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="top">
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ fontWeight: "bold" }}
                          >
                            Effekt:
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ marginLeft: 0.5 }}
                          >
                            {plant.Effect.join(", ")}
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="top">
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ fontWeight: "bold" }}
                          >
                            Geschmack:
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ marginLeft: 0.5 }}
                          >
                            {plant.Taste.join(", ")}
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  ))}
                </Box>
              </Box>
            </Grid>
            {/* {!matches && (
        <Grid item="true" xs={4}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            component={Paper}
            padding={4}
            width="90%"
            margin="auto"
            marginTop={5}
            minHeight={500}
          >
            <OpeningTimes />
          </Box>
        </Grid>)} */}
          </Grid>
      </div>
    </HelmetProvider>
        </>
  );
};

export default Stecklinge;
