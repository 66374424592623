import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import {supabase} from "../../../config/supabaseClient.js";
import { useState, useEffect } from "react";

export default function ChangeRoleDialog(props) {
  const { role, id, memberId, columnIndex, members, setMembers, open, onClose } = props;

  const [newRole, setNewRole] = useState("");

  const handleChangeRole = async () => {
    try {
      if (id) {
        // Aktualisiere die lokale State-Variable
        setMembers((prevMembers) => {
          const updatedMembers = prevMembers.map((member) =>
            member.id === id
              ? {
                  ...member,
                  role: newRole,
                  member_since: newRole === "Mitglied" && role === "Registriert"  ? new Date().toLocaleDateString() : member.member_since,
                }
              : member
          );
          return updatedMembers;
        });
  
        // Aktualisiere die Daten in der supabase-Tabelle "User"
        const { data, error } = await supabase
          .from("User")
          .update({
            role: newRole,
            member_since: newRole === "Mitglied" && role === "Registriert"? new Date().toLocaleDateString() : null,
          })
          .eq("id", id);
  
          if (error) {
            console.error("Fehler beim Aktualisieren der Rolle:", error);
            console.error("Supabase-Fehlerdetails:", error.details);
            throw error;
          }
          
          //console.log("Supabase-Antwortdaten:", data);
      }
  
      onClose(); // Schließe das Dialog-Fenster nach erfolgreicher Aktualisierung
    } catch (error) {
      console.error("Fehler beim Aktualisieren der Rolle:", error);
      // Handle den Fehler entsprechend
    }
  };
  

  return (
    <React.Fragment>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle> Status ändern</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" sx={{ marginTop: 1 }}>
              Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={newRole}
              label="Status"
              sx={{ marginTop: 1 }}
              onChange={(e) => {
                setNewRole(e.target.value);
              }}
            >
              <MenuItem value={"Registriert"}>Registriert</MenuItem>
              <MenuItem value={"Prime-Mitglied"}>Prime-Mitglied</MenuItem>
              <MenuItem value={"Mitglied"}>Mitglied</MenuItem>
              <MenuItem value={"neuer Helfer"}>neuer Helfer</MenuItem>
              <MenuItem value={"Helfer"}>Helfer</MenuItem>
              <MenuItem value={"Vorstand"}>Vorstand</MenuItem>
              <MenuItem value={"Admin"}>Admin</MenuItem>
              <MenuItem value={"Bann"}>Bann</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Schließen</Button>
          <Button onClick={handleChangeRole}>Ändern</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
