import * as React from "react";
import Button from "@mui/material/Button";
import { TextField, capitalize } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import {supabase} from "../../../config/supabaseClient.js";
import { useState, useEffect } from "react";
import { token, setToken } from "../../../App.js";
import { format } from "date-fns";
import dayjs from "dayjs";
import { differenceInYears } from "date-fns";

//comps
import Vereinspflege from "../../../pdf/Änderung zur Vereinspflege.pdf";
import Privatpflege from "../../../pdf/Änderung zur Privatpflege.pdf";
import Pausieren from "../../../pdf/Änderung zur Mitgliedschaft pausieren.pdf";

const STREET_REGEX = /[a-zA-Z][a-zA-Z0-9-_.]{2,40}$/;
const NUMBER_REGEX = /^\d{1,3}$/;
const NUMBERADD_REGEX = /^[a-zA-Z]{1}$/;
const PLZ_REGEX = /^\d{5}$/;
const CITY_REGEX = /[a-zA-Z]$/;
const MAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const PHONE_REGEX = /^[\+]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/;
const PWD_REGEX = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const DEMAND_REGEX = /^(?:[0-9]|[1-4][0-9]|50)$/;
const U21DEMAND_REGEX = /^(?:[0-9]|[1-2][0-9]|30)$/;

export default function ChangeProfileRequestDialog(props) {
  const { setToken, token, role, UserData, membershipModel } = props;
  const today = dayjs();
  const date = dayjs(UserData[0].date_of_birth, "DD.MM.YYYY");
  const age = differenceInYears(today.toDate(), date.toDate());

  const [new_street, setStreet] = useState("");
  const [validStreet, setValidStreet] = useState(false);

  const [new_housenumber, setNumber] = useState("");
  const [validNumber, setValidNumber] = useState(false);
  const [NumberFocus, setNumberFocus] = useState(false);

  const [new_housenumberadd, setNumberAdd] = useState("");
  const [validNumberAdd, setValidNumberAdd] = useState(false);
  const [NumberAddFocus, setNumberAddFocus] = useState(false);

  const [new_plz, setPLZ] = useState("");
  const [validPLZ, setValidPLZ] = useState(false);
  const [PLZFocus, setPLZFocus] = useState(false);

  const [new_city, setCity] = useState("");
  const [validCity, setValidCity] = useState(false);
  const [CityFocus, setCityFocus] = useState(false);

  const [new_email, setEMail] = useState("");
  const [validEMail, setValidEMail] = useState(false);
  const [EMailFocus, setEMailFocus] = useState(false);

  const [new_phonenr, setPhoneNr] = useState("");
  const [validPhoneNr, setValidPhoneNr] = useState(false);
  const [PhoneNrFocus, setPhoneNrFocus] = useState(false);

  const [new_password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [PasswordFocus, setPasswordFocus] = useState(false);

  const [new_matchpassword, setMatchPassword] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [old_password, setOldPassword] = useState("");
  const [validOldPassword, setValidOldPassword] = useState(false);

  const [new_model, setModel] = useState("");

  const [new_demand, setDemand] = useState("");
  const [validDemand, setValidDemand] = useState(false);
  const [new_lastchangedemand, setLastChange] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [errMsg, setErrMsg] = useState("");

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    const result = STREET_REGEX.test(new_street);
    //console.log("street: " + new_street + " " + result);
    setValidStreet(result);
  }, [new_street]);

  useEffect(() => {
    const result = NUMBER_REGEX.test(new_housenumber);
    //console.log("housenumber: " + new_housenumber + " " + result);
    setValidNumber(result);
  }, [new_housenumber]);

  useEffect(() => {
    const result = NUMBERADD_REGEX.test(new_housenumberadd);
    //console.log("housenumber zusatz: " + new_housenumberadd + " " + result);
    setValidNumberAdd(result);
  }, [new_housenumberadd]);

  useEffect(() => {
    const result = PLZ_REGEX.test(new_plz);
    //console.log("plz: " + new_plz + " " + result);
    setValidPLZ(result);
  }, [new_plz]);

  useEffect(() => {
    const result = CITY_REGEX.test(new_city);
    //console.log("city: " + new_city + " " + result);
    setValidCity(result);
  }, [new_city]);

  useEffect(() => {
    const result = MAIL_REGEX.test(new_email);
    //console.log("Email: " + new_email + " " + result);
    setValidEMail(result);
  }, [new_email]);

  useEffect(() => {
    const result = PHONE_REGEX.test(new_phonenr);
    //console.log("Tel.: " + new_phonenr + " " + result);
    setValidPhoneNr(result);
  }, [new_phonenr]);

  /* useEffect(()=>{
    const result = old_password === token.user.password;
    setValidOldPassword(result);
  },[old_password, token]) */

  useEffect(() => {
    const result = PWD_REGEX.test(new_password);
    setValidPassword(result);
    const match = new_password === new_matchpassword;
    setValidMatch(match);
  }, [new_password, new_matchpassword]);

  useEffect(() => {
    const result = PWD_REGEX.test(old_password);
    setValidOldPassword(result);
    //console.log("Old PW.: " + old_password + " " + result);
  }, [old_password]);

  useEffect(() => {
    if (age >= 21) {
      const result = DEMAND_REGEX.test(new_demand);
      //console.log("Demand: " + new_demand + " " + result + age);
      setValidDemand(result);
    } else {
      const result = U21DEMAND_REGEX.test(new_demand);
      //console.log("Demand: " + new_demand + " " + result + age);
      setValidDemand(result);
    }
  }, [new_demand]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const changeUserData = async (e) => {
    e.preventDefault();
    if (props.changerequest === "E-Mail Adresse") {
      const v5 = MAIL_REGEX.test(new_email);
      if (!v5) {
        setErrMsg("Ungültige Eingabe");
        console.log("das war nichts!");
        console.log(MAIL_REGEX.test(new_email));
        return;
      }
      const formData = {
        email: new_email,
      };
      //console.log(formData);
      try {
        const { data, error } = await supabase.auth.updateUser({
          email: formData.email,
        });
        if (error) throw error;
        setToken(data);
        localStorage.setItem("token", JSON.stringify(data));
      } catch (error) {
        alert(error.message);
      }
      alert(
        "Dir wurden E-Mails an beide Addressen gesendet. Du musst die Änderung auf beiden Mails bestätigen bevor die Änderung wirksam wird."
      );
      setOpen(false);
    } else if (props.changerequest === "Telefonnummer") {
      const v1 = PHONE_REGEX.test(new_phonenr);
      if (!v1) {
        setErrMsg("Ungültige Eingabe");
        console.log("das war nichts!");
        console.log(PHONE_REGEX.test(new_phonenr));
        return;
      }
      const formData = {
        new_phonenr: new_phonenr,
      };
      //console.log(formData);
      try {
        const { data, error } = await supabase
          .from("User")
          .update({ phone_nr: formData.new_phonenr })
          .eq("id", token.user.id);
        if (error) throw error;
      } catch (error) {
        alert(error.message);
      }
      setOpen(false);
      window.location.reload(false);
    } else if (props.changerequest === "Anschrift") {
      const v2 = STREET_REGEX.test(new_street);
      const v3 = NUMBER_REGEX.test(new_housenumber);
      const v4 = PLZ_REGEX.test(new_plz);
      const v5 = CITY_REGEX.test(new_city);
      const v6 =
        new_housenumberadd === ""
          ? true
          : NUMBERADD_REGEX.test(new_housenumberadd);
      if (!v2 || !v3 || !v4 || !v5 || !v6) {
        setErrMsg("Ungültige Eingabe");
        console.log("das war nichts!");
        console.log(
          STREET_REGEX.test(new_street),
          NUMBER_REGEX.test(new_housenumber),
          NUMBERADD_REGEX.test(new_housenumberadd),
          PLZ_REGEX.test(new_plz),
          CITY_REGEX.test(new_city)
        );
        return;
      }
      const formData = {
        new_street: new_street,
        new_housenumber: new_housenumber,
        new_housenumberadd: new_housenumberadd,
        new_plz: new_plz,
        new_city: new_city,
      };
      //console.log(formData);
      try {
        const { data, error } = await supabase
          .from("User")
          .update({
            street: formData.new_street,
            housenumber: formData.new_housenumber,
            housenumber_add: formData.new_housenumberadd,
            city: formData.new_city,
            zip_code: formData.new_plz,
          })
          .eq("id", token.user.id);
        if (error) throw error;
      } catch (error) {
        alert(error.message);
      }
      setOpen(false);
      window.location.reload(false);
    } else if (props.changerequest === "Mitgliedsmodel") {
      /* const formData = {
        new_model: new_model,
      };
      //console.log(formData);
      try {
        const { data, error } = await supabase
          .from("User")
          .update({ model: formData.new_model })
          .eq("id", token.user.id);
        if (error) throw error;
      } catch (error) {
        alert(error.message);
      }
      setOpen(false);
      window.location.reload(false); */

      if (new_model === "Vereinspflege") {
        // Step 1: Load the PDF form.
        const formUrl = Vereinspflege;
        const formPdfBytes = await fetch(formUrl).then((res) =>
          res.arrayBuffer()
        );
        const pdfDoc = await PDFDocument.load(formPdfBytes);

        // Step 2: Retrieve the form fields. (for saving space I have skipped the most of the code. Instead I've added form.getTextField('Text1'). in the next step)
        const form = pdfDoc.getForm();

        // Step 3: Set values for the form fields.
        const today = format(new Date(), "dd.MM.yyyy");
        form
          /* .getTextField("Text1")
      .setText(UserData[0].lastname + ", " + UserData[0].firstname); */
          .getTextField("Text1")
          .setText(UserData[0].firstname);
        form.getTextField("Text2").setText(UserData[0].lastname);
        form.getTextField("Text3").setText(UserData[0].date_of_birth);
        form.getTextField("Text4").setText("Hannover, " + today);

        // Step 4: Save the modified PDF.
        const pdfBytes = await pdfDoc.save();

        // Step 5: Create a `Blob` from the PDF bytes,
        const blob = new Blob([pdfBytes], { type: "application/pdf" });

        // Step 6: Create a download URL for the `Blob`.
        const url = URL.createObjectURL(blob);

        // Step 7: Create a link element and simulate a click event to trigger the download.
        const link = document.createElement("a");
        link.href = url;
        link.download = "Änderung zur Vereinspflege.pdf";
        link.click();
      } else if (new_model === "Privatpflege") {
        // Step 1: Load the PDF form.
        const formUrl = Privatpflege;
        const formPdfBytes = await fetch(formUrl).then((res) =>
          res.arrayBuffer()
        );
        const pdfDoc = await PDFDocument.load(formPdfBytes);

        // Step 2: Retrieve the form fields. (for saving space I have skipped the most of the code. Instead I've added form.getTextField('Text1'). in the next step)
        const form = pdfDoc.getForm();

        // Step 3: Set values for the form fields.
        const today = format(new Date(), "dd.MM.yyyy");
        form
          /* .getTextField("Text1")
      .setText(UserData[0].lastname + ", " + UserData[0].firstname); */
          .getTextField("Text1")
          .setText(UserData[0].firstname);
        form.getTextField("Text2").setText(UserData[0].lastname);
        form.getTextField("Text3").setText(UserData[0].date_of_birth);
        form.getTextField("Text4").setText("Hannover, " + today);

        // Step 4: Save the modified PDF.
        const pdfBytes = await pdfDoc.save();

        // Step 5: Create a `Blob` from the PDF bytes,
        const blob = new Blob([pdfBytes], { type: "application/pdf" });

        // Step 6: Create a download URL for the `Blob`.
        const url = URL.createObjectURL(blob);

        // Step 7: Create a link element and simulate a click event to trigger the download.
        const link = document.createElement("a");
        link.href = url;
        link.download = "Änderung zur Privatpflege.pdf";
        link.click();
      } else if (new_model === "Mitgliedschaft pausieren") {
        // Step 1: Load the PDF form.
        const formUrl = Pausieren;
        const formPdfBytes = await fetch(formUrl).then((res) =>
          res.arrayBuffer()
        );
        const pdfDoc = await PDFDocument.load(formPdfBytes);

        // Step 2: Retrieve the form fields. (for saving space I have skipped the most of the code. Instead I've added form.getTextField('Text1'). in the next step)
        const form = pdfDoc.getForm();

        // Step 3: Set values for the form fields.
        const today = format(new Date(), "dd.MM.yyyy");
        form
          /* .getTextField("Text1")
      .setText(UserData[0].lastname + ", " + UserData[0].firstname); */
          .getTextField("Text1")
          .setText(UserData[0].firstname);
        form.getTextField("Text2").setText(UserData[0].lastname);
        form.getTextField("Text3").setText(UserData[0].date_of_birth);
        form.getTextField("Text5").setText("Hannover, " + today);

        // Step 4: Save the modified PDF.
        const pdfBytes = await pdfDoc.save();

        // Step 5: Create a `Blob` from the PDF bytes,
        const blob = new Blob([pdfBytes], { type: "application/pdf" });

        // Step 6: Create a download URL for the `Blob`.
        const url = URL.createObjectURL(blob);

        // Step 7: Create a link element and simulate a click event to trigger the download.
        const link = document.createElement("a");
        link.href = url;
        link.download = "Änderung zur Mitgliedschaft pausieren.pdf";
        link.click();
      }
    } else if (props.changerequest === "Monatsbedarf") {
      //console.log(props);
      if (age >= 21) {
        const v5 = DEMAND_REGEX.test(new_demand);
        if (!v5) {
          setErrMsg("Ungültige Eingabe");
          console.log("das war nichts!");
          console.log(DEMAND_REGEX.test(new_demand));
          return;
        }
      } else {
        const v5 = U21DEMAND_REGEX.test(new_demand);
        if (!v5) {
          setErrMsg("Ungültige Eingabe");
          console.log("das war nichts!");
          console.log(U21DEMAND_REGEX.test(new_demand));
          return;
        }
      }

      const formData = {
        new_demand: new_demand,
        new_lastchangedemand: new_lastchangedemand,
      };
      //console.log(formData);
      try {
        const { data, error } = await supabase
          .from("User")
          .update({
            demand_next_month: formData.new_demand,
            last_change_demand: formData.new_lastchangedemand,
          })
          .eq("id", token.user.id);
        if (error) throw error;
        //console.log(token.user.id)
      } catch (error) {
        alert(error.message);
      }
      setOpen(false);
      window.location.reload(false);
    } else if (props.changerequest === "Passwort") {
      /* if (!validOldPassword) {
        setErrMsg("Ungültiges altes Passwort");
        return;
      } */
      const v5 = PWD_REGEX.test(new_password);
      if (!v5) {
        setErrMsg("Ungültige Eingabe");
        console.log("das war nichts!");
        console.log(PWD_REGEX.test(new_password));
        return;
      }
      // Überprüfen, ob das neue Passwort gleich dem alten Passwort ist
/*       if (old_password === new_password) {
        setErrMsg(
          "Das neue Passwort muss sich vom alten Passwort unterscheiden"
        );
        return; 
      }*/
      const formData = {
        new_password: new_password,
      };
      //console.log(token.user.email);
      try {
        const { data, error } = await supabase.auth.updateUser({
          password: formData.new_password,
        });
        console.log(new_password, formData.new_password);
        if (error) throw error;
      } catch (error) {
        alert(
          error.message +
            " Falls 'new password should be different from old password' erscheint, lasst euch nicht verwirren, die Änderung war trotzdem erfolgreich."
        );
      }
      setOpen(false);
      window.location.reload(false);
    }
  };

  const { PDFDocument } = require("pdf-lib");
  /* const pwrecovery = async (e) => {
    let { data, error } = await supabase.auth.resetPasswordForEmail(
      token.user.email
    );
    console.log("pw recovery durchgeführt");
  }; */

  return (
    <React.Fragment>
      {props.changerequest === "Passwort" ? (
        <Button
          className="button"
          variant="contained"
          onClick={handleClickOpen}
        >
          Passwort ändern
        </Button>
      ) : props.role === "Registriert" ? (
        <Button
          variant="outlined"
          onClick={handleClickOpen}
          size="small"
          disabled
        >
          Ändern
        </Button>
      ) : UserData[0].model === "Privatpflege" || UserData[0].model === "Ehrenmitglied-Privatpflege" &&
        props.changerequest === "Monatsbedarf" ? (
        <Button
          variant="outlined"
          onClick={handleClickOpen}
          size="small"
          disabled
        >
          Ändern
        </Button>
      ) : (
        <Button variant="outlined" onClick={handleClickOpen} size="small">
          Ändern
        </Button>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{props.changerequest} ändern</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {/* Bitte gib deine neue {props.changerequest} ein. */}
            {props.changerequest === "E-Mail Adresse"
              ? "Bitte gib deine neue E-Mail Adresse ein."
              : props.changerequest === "Telefonnummer"
              ? "Bitte gib deine neue Telefonnummer ein."
              : props.changerequest === "Anschrift"
              ? "Bitte gib deine neue Anschrift ein."
              : props.changerequest === "Mitgliedsmodel"
              ? "Bitte wähle dein neues gewünschtes Mitgliedsmodel. Mit dem Klicken auf 'Antrag herunterladen' wird automatische ein Antrag heruntergeladen, der vollständig ausgefüllt im Vereinsheim abegeben werden muss."
              : props.changerequest === "Monatsbedarf"
              ? "Bitte gib deinen gewünschten Bedarf an"
              : props.changerequest === "Passwort"
              ? "Bist du dir sicher, dass du dein Passwort ändern möchtest?"
              : ""}
          </DialogContentText>
          {props.changerequest === "E-Mail Adresse" ? (
            <TextField
              autoFocus
              margin="dense"
              id="new_email"
              label="E-Mail Adresse"
              color={validEMail ? "success" : "error"}
              onChange={(e) => {
                setEMail(e.target.value);
              }}
              type="email"
              fullWidth
              variant="standard"
              onFocus={() => setEMailFocus(true)}
              onBlur={() => setEMailFocus(false)}
              helperText={
                !EMailFocus
                  ? ""
                  : validEMail
                  ? ""
                  : "Muss mindestens 6 Zeichen besitzen. Muss ein @ und einen . (Punkt) enthalten."
              }
            />
          ) : props.changerequest === "Telefonnummer" ? (
            <TextField
              autoFocus
              margin="dense"
              id="new_phonenr"
              label="Telefonnummer"
              color={validPhoneNr ? "success" : "error"}
              onChange={(e) => {
                setPhoneNr(e.target.value);
              }}
              type="tel"
              fullWidth
              variant="standard"
              onFocus={() => setPhoneNrFocus(true)}
              onBlur={() => setPhoneNrFocus(false)}
              helperText={
                !PhoneNrFocus
                  ? ""
                  : validPhoneNr
                  ? ""
                  : "Nur Nummern sind erlaubt."
              }
            />
          ) : props.changerequest === "Anschrift" ? (
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="new_street"
                label="Straße"
                color={validStreet ? "success" : "error"}
                onChange={(e) => {
                  setStreet(capitalize(e.target.value));
                }}
                type="text"
                fullWidth
                variant="standard"
              />
              <TextField
                margin="dense"
                id="new_housenumber"
                label="Hausnummer"
                color={validNumber ? "success" : "error"}
                onChange={(e) => {
                  setNumber(e.target.value);
                }}
                type="text"
                fullWidth
                variant="standard"
                onFocus={() => setNumberFocus(true)}
                onBlur={() => setNumberFocus(false)}
                helperText={
                  !NumberFocus ? "" : validNumber ? "" : "Maximal 3-stellig"
                }
              />
              <TextField
                margin="dense"
                id="new_housenumberadd"
                label="Hausnummer Zusatz"
                color={validNumberAdd ? "success" : "error"}
                onChange={(e) => {
                  setNumberAdd(capitalize(e.target.value));
                }}
                type="text"
                fullWidth
                variant="standard"
                onFocus={() => setNumberAddFocus(true)}
                onBlur={() => setNumberAddFocus(false)}
                helperText={
                  !NumberAddFocus
                    ? ""
                    : validNumberAdd
                    ? ""
                    : "Maximal 1-stellig"
                }
              />
              <TextField
                margin="dense"
                id="new_plz"
                label="Postleitzahl"
                color={validPLZ ? "success" : "error"}
                onChange={(e) => {
                  setPLZ(e.target.value);
                }}
                type="number"
                fullWidth
                variant="standard"
                onFocus={() => setPLZFocus(true)}
                onBlur={() => setPLZFocus(false)}
                helperText={
                  !PLZFocus
                    ? ""
                    : validPLZ
                    ? ""
                    : "Die PLZ (Postleitzahl) muss 5 Stellen haben und darf nur aus Zahlen bestehen"
                }
              />
              <TextField
                margin="dense"
                id="new_city"
                label="Stadt"
                color={validCity ? "success" : "error"}
                onChange={(e) => {
                  setCity(capitalize(e.target.value));
                }}
                type="text"
                fullWidth
                variant="standard"
                onFocus={() => setCityFocus(true)}
                onBlur={() => setCityFocus(false)}
                helperText={
                  !CityFocus
                    ? ""
                    : validCity
                    ? ""
                    : "Muss mindestens 3 Buchstaben besitzen"
                }
              />
            </div>
          ) : props.changerequest === "Mitgliedsmodel" ? (
            <Box sx={{ minWidth: 120, paddingTop: 5 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Modell</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={new_model}
                  label="model"
                  onChange={(e) => {
                    setModel(e.target.value);
                  }}
                >
                  {membershipModel === "Vereinspflege" ? (
                    [
                      <MenuItem key="privatpflege" value={"Privatpflege"}>
                        Privatpflege
                      </MenuItem>,
                    ]
                  ) : membershipModel === "Privatpflege" ? (
                    [
                      <MenuItem key="vereinspflege" value={"Vereinspflege"}>
                        Vereinspflege
                      </MenuItem>,
                    ] /* : membershipModel === "Mitgliedschaft pausieren" ? (
                    [
                      <MenuItem key="privatpflege" value={"Privatpflege"}>
                        Privatpflege
                      </MenuItem>,
                      <MenuItem key="vereinspflege" value={"Vereinspflege"}>
                        Vereinspflege
                      </MenuItem>,
                    ]
                  ) */
                  ) : (
                    <MenuItem key="default" value={"Vereinspflege"}></MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>
          ) : props.changerequest === "Monatsbedarf" ? (
            <Box>
              <TextField
                autoFocus
                margin="dense"
                id="new_demand"
                label="Monatsbedarf"
                color={validDemand ? "success" : "error"}
                onChange={(e) => {
                  setDemand(e.target.value);
                  setLastChange(new Date());
                }}
                type="number"
                fullWidth
                variant="standard"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">g</InputAdornment>
                  ),
                }}
                aria-describedby="demand-helper-text"
              />
              <FormHelperText id="demand-helper-text">
                {validDemand
                  ? ""
                  : "Wenn du unter 21 Jahre alt bist, ist die Maximalgrenze 30 g; ansonsten 50 g"}
              </FormHelperText>
            </Box>
          ) : props.changerequest === "Passwort" ? (
            <Box>
              <FormControl sx={{ m: 1 / 2, minWidth: 420 }}>
                <InputLabel htmlFor="outlined-adornment-password">
                  Neues Passwort
                </InputLabel>
                <OutlinedInput
                  id="new_password"
                  label="Neues Passwort"
                  color={validPassword ? "success" : "error"}
                  onChange={(e) => {
                    setPassword(e.target.value); // Ändern Sie diese Zeile
                  }}
                  type={showPassword ? "text" : "password"}
                  variant="standard"
                  onFocus={() => setPasswordFocus(true)}
                  onBlur={() => setPasswordFocus(false)}
                  aria-describedby="pw-helper-text"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText id="pw-helper-text">
                  {!PasswordFocus
                    ? ""
                    : validPassword
                    ? ""
                    : "8 bis 24 Zeichen. Muss eine Groß- und Kleinbuchstaben, eine Nummer und ein Sonderzeichen enthalten. Erlaubte Sonderzeichen sind: !, @, #, $, %"}
                </FormHelperText>
              </FormControl>
              <FormControl sx={{ m: 1 / 2, minWidth: 420 }}>
                <InputLabel htmlFor="outlined-adornment-password">
                  Neues Passwort bestätigen
                </InputLabel>
                <OutlinedInput
                  autoFocus
                  margin="dense"
                  id="new_passwordmatch"
                  label="Neues Passwort bestätigen"
                  color={validMatch ? "success" : "error"}
                  onChange={(e) => {
                    setMatchPassword(e.target.value);
                  }}
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  variant="standard"
                  onFocus={() => setMatchFocus(true)}
                  onBlur={() => setMatchFocus(false)}
                  aria-describedby="pw-helper-text"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText id="pwm-helper-text">
                  {!matchFocus
                    ? ""
                    : validMatch
                    ? ""
                    : "Die Eingabe stimmt nicht mit dem Passwort überein"}
                </FormHelperText>
              </FormControl>
            </Box>
          ) : (
            ""
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
          {props.changerequest === "E-Mail Adresse" ? (
            <Button onClick={changeUserData}>Ändern</Button>
          ) : props.changerequest === "Telefonnummer" ? (
            <Button onClick={changeUserData}>Ändern</Button>
          ) : props.changerequest === "Anschrift" ? (
            <Button onClick={changeUserData}>Ändern</Button>
          ) : props.changerequest === "Mitgliedsmodel" ? (
            <Button onClick={changeUserData}>Antrag herunterladen</Button>
          ) : props.changerequest === "Monatsbedarf" ? (
            <Button onClick={changeUserData}>Ändern</Button>
          ) : props.changerequest === "Passwort" ? (
            <Button onClick={changeUserData}>Ändern</Button>
          ) : (
            ""
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
